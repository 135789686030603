import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import { setActiveSort } from "../../helpers/product";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

const ShopTag = ({ tags, getSortParams, removeSortParams }) => {
 

  // const tagHandler = (e,tag) => {
  //   // getSortParams("tag", tag);
  //   console.log(e.target.className)
  //   setActiveSort(e);
  // }

  const [selectedTags, setSelectedTags] = useState([])

  useEffect(() => {
    if(selectedTags){
    getSortParams("tag", selectedTags);
    }
  }, [selectedTags])

  const tagHandler = (e, tag) => {
    if(e.target.className === `tag-btn active`){
      e.target.className = `tag-btn`
      removeSortParams("tag", tag)
      setSelectedTags(selectedTags.filter(c => c !== tag))
    }else{
      setSelectedTags([tag])
      e.target.className = `tag-btn active`
    }
  }

  
  return (
    <div className="sidebar-widget mt-20">
      {/* <h4 className="pro-sidebar-title">Tag </h4> */}
      <div className="sidebar-widget-tag mt-25">
        {tags ? (
          <div className="filter-wrapper">
          <Accordion defaultActiveKey="0">
            <Card className="single-my-account mb-20">
              <Card.Header className="panel-heading">
                <Accordion.Toggle variant="link" eventKey="2">
                  <h3 className="panel-title">
                  Tag
                  </h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2" >
                <Card.Body>
                  <div className="">
                  <ul>
            {tags.slice(0,20).map((tag, key) => {
              return (
                <li key={key}>
                  <button
                  className="tag-btn"
                    onClick={e => tagHandler(e,tag)}
                  >
                    {tag}
                  </button>
                </li>
              );
            })}
          </ul>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
          
        ) : (
          "No tags found"
        )}
      </div>
    </div>
  );
};

ShopTag.propTypes = {
  getSortParams: PropTypes.func,
  tags: PropTypes.array
};

export default ShopTag;
