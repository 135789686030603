import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import { setActiveSort } from "../../helpers/product";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

const ShopCategories = ({ categories, getSortParams, removeSortParams}) => {

  const [selectedCategory, setSelectedCategory] = useState([])

  useEffect(() => {
    if(selectedCategory && selectedCategory.length>0){
    getSortParams("category", selectedCategory);
    }
  }, [selectedCategory])

  const categoryHandler = (e, size) => {
    

    if(e.currentTarget.className === `active`){
      e.currentTarget.classList.remove("active")
      removeSortParams("category", size)
      setSelectedCategory(selectedCategory.filter(c => c !== size))
    }else{
      setSelectedCategory([size])
      e.currentTarget.classList.add("active")
    }
  }



  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title">Filter by </h4>
      <div className="sidebar-widget-list mt-20">
        {categories && categories.length>0 ? (
          <ul>

            {categories.filter(c => c === "men" || c === "women")[0] && <li>
                {/* <div className="sidebar-widget-list-left"> 
                      {" "}
                      <span />Adults{" "}
                  </div> */}
                  <div className="filter-wrapper">
                  <Accordion defaultActiveKey="0">
                    <Card className="single-my-account mb-20">
                      <Card.Header className="panel-heading">
                        <Accordion.Toggle variant="link" eventKey="0" className="sideb">
                          <h3 className="panel-title">
                          Adults
                          </h3>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="">
                            <ul>
            {categories.filter(c => c === "men")[0] && <li >
                  <div className="sidebar-widget-list-left">
                    <button 
                      onClick={(e)=>categoryHandler(e, "men")}
                    >
                      {" "}
                      <span className="checkmark fa fa-check" /> Men{" "}
                    </button>
                  </div>
              </li>}
              {categories.filter(c => c === "women")[0] && <li>
                  <div className="sidebar-widget-list-left">
                    <button 
                      onClick={(e)=>categoryHandler(e, "women")}
                    >
                      {" "}
                      <span className="checkmark fa fa-check" /> Women{" "}
                    </button>
                  </div>
              </li>}
              </ul>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              

              </li>}
              
              
          </ul>
        ) : (
          "No categories found"
        )}
      </div>
    </div>
  );
};

ShopCategories.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func,
  removeSortParams: PropTypes.func
};

export default ShopCategories;