import axios from 'axios'

export const SLIDER_UPDATE_REQUEST = "SLIDER_UPDATE_REQUEST";
export const SLIDER_UPDATE_SUCCESS = "SLIDER_UPDATE_SUCCESS";
export const SLIDER_UPDATE_FAIL = "SLIDER_UPDATE_FAIL";
export const SLIDER_UPDATE_RESET = "SLIDER_UPDATE_RESET";

export const SLIDER_FETCH_REQUEST = "SLIDER_FETCH_REQUEST";
export const SLIDER_FETCH_SUCCESS = "SLIDER_FETCH_SUCCESS";
export const SLIDER_FETCH_FAIL = "SLIDER_FETCH_FAIL";


export const fetchSlider = () => async (
  dispatch
) => {
  try {
    dispatch({ type: SLIDER_FETCH_REQUEST })

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/slider`
    )

    dispatch({
      type: SLIDER_FETCH_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SLIDER_FETCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}


export const updateSlider = (slider, addToast) => async (dispatch, getState) => {

  try {
    dispatch({
      type: SLIDER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/slider/${slider._id}`,
      slider,
      config
    )
    if (addToast) {
      addToast("Slider Info Added", { appearance: "success", autoDismiss: true });
    }
    dispatch({
      type: SLIDER_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    dispatch({
      type: SLIDER_UPDATE_FAIL,
      payload: message,
    })
  }
}



