import PropTypes from "prop-types";
import React,{useState, useEffect} from "react";
import {
  getIndividualCategories,
  getIndividualTags,
  getIndividualColors,
  getProductsIndividualSizes
} from "../../helpers/product2";
import ShopSearch from "../../components/product/ShopSearch";
import ShopCategories from "../../components/product/ShopCategories";
import ShopCategories1 from "../../components/product/ShopCategories1";

import ShopColor from "../../components/product/ShopColor";
import ShopSize from "../../components/product/ShopSize";
import ShopSize1 from "../../components/product/ShopSize1";
import ShopTag from "../../components/product/ShopTag";
import ShopColor1 from "../../components/product/ShopColor1";
// import Card from "react-bootstrap/Card";
// import Accordion from "react-bootstrap/Accordion";

const ShopSidebar = ({ products, getSortParams, sideSpaceClass, removeSortParams, getFilterSortParams, category, sortedProducts }) => {
  const uniqueCategories = getIndividualCategories(products);
  const uniqueColors = getIndividualColors(products);
  const uniqueSizes = getProductsIndividualSizes(products);
  var uniqueTags = getIndividualTags((sortedProducts && sortedProducts[0])? sortedProducts:products);
  // const searchProduct = getSearchProduct(products);  
  const [filter, setFilter] = useState(false)

  return (
    <>
    <button className="btn btn-secondary filter-btn" onClick={()=>setFilter(!filter)}>
      filter <i className="fa fa-plus"></i> 
      </button>
    <div className={`sidebar-style box-side ${filter? "mobile-filter" : "mobile-filter-hide"} ${sideSpaceClass ? sideSpaceClass : ""}`}>
      {/* shop search */}
      
      <ShopSearch getSortParams={getSortParams} removeSortParams={removeSortParams}/>


      {/* filter by categories */}
      <ShopCategories
        categories={uniqueCategories}
        getSortParams={getSortParams}
        removeSortParams={removeSortParams}
      />
 {/* filter by size */}
 {uniqueCategories.filter(c => c === "men" || c === "women")[0] &&
 <ShopSize sizes={uniqueSizes} getSortParams={getSortParams} removeSortParams={removeSortParams}/>
 }
{/* filter by categories */}

<ShopCategories1
        categories={uniqueCategories}
        getSortParams={getSortParams}
        removeSortParams={removeSortParams}
      />
      {/* filter by size */}
      {uniqueCategories.filter(c => c === "boys" || c === "girls")[0] &&
 <ShopSize1 sizes={uniqueSizes} getSortParams={getSortParams} removeSortParams={removeSortParams}/>
}

      {/* filter by color */}
      {uniqueColors && <ShopColor colors={uniqueColors} getSortParams={getSortParams} removeSortParams={removeSortParams}/>}
     
      {/* filter by tag */}
      {!category && <ShopTag tags={uniqueTags} getSortParams={getSortParams} removeSortParams={removeSortParams}/>}
    </div>
    </>
  );
};

ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string,
  sortedProducts: PropTypes.array,
  removeSortParams: PropTypes.func
};

export default ShopSidebar;