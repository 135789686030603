// get products
export const getProducts = (products, category, type, limit) => {
  const finalProducts = category
    ? products.filter(
        product => {
          if(product.category.length>=1){
            return product.category.filter(single => single.value === category)[0]
        }else{
          return (product.category[0].value === category)
        }
        }
      )
    : products;

  if (type && type === "new") {
    const newProducts = finalProducts.filter(single => single.new);
    return newProducts.slice(0, limit ? limit : newProducts.length);
  }
  if (type && type === "bestSeller") {
    return finalProducts
      .sort((a, b) => {
        return b.saleCount - a.saleCount;
      })
      .slice(0, limit ? limit : finalProducts.length);
  }
  if (type && type === "saleItems") {
    const saleItems = finalProducts.filter(
      single => single.discount && single.discount > 0
    );
    return saleItems.slice(0, limit ? limit : saleItems.length);
  }
  return finalProducts.slice(0, limit ? limit : finalProducts.length);
};

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : null;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product, color, size) => {
  let productInCart = cartItems.filter(
    single =>
      single._id === product._id &&
      (single.selectedProductColor
        ? single.selectedProductColor === color
        : true) &&
      (single.selectedProductSize ? single.selectedProductSize === size : true)
  )[0];
  if (cartItems.length >= 1 && productInCart) {
    if (product.variation) {
      return cartItems.filter(
        single =>
          single._id === product._id &&
          single.selectedProductColor === color &&
          single.selectedProductSize === size
      )[0];
    } else {
      return cartItems.filter(single => product._id === single._id)[0];
    }
  } else {
    return 0;
  }
};

// get products based on category
export const getSortedProducts = (products, sortType, sortValue, searchValue) => {
  var finalFilteredProduct = products


  if (products && products.length>0 && sortType && sortValue && sortValue.length>0) {
    sortType = getIndividualItemArray(sortType)
    sortValue = getIndividualItemArray(sortValue)

    if(sortType.filter(t=>t==="search")[0] === "search"){
      
      if(searchValue){
      var searchProduct = []
      if(searchValue){
     products.filter(product =>
       {
        if(product.category[0].value.toLowerCase().includes(searchValue.toLowerCase())){
          searchProduct = [...searchProduct, product]
      }    
         else if(product.name.toLowerCase().includes(searchValue.toLowerCase())){
           searchProduct = [...searchProduct, product]
       }
       else if(product.color.value && product.color.value.toLowerCase().includes(searchValue.toLowerCase())){
           searchProduct = [...searchProduct, product]
       }else{
         product.tag.forEach(t => {
           if(t.value.toLowerCase().includes(searchValue.toLowerCase())){
             searchProduct = [...searchProduct, product]
           }
         })
       }
     }
     )
    //  if(searchProduct.length>0){
      
     finalFilteredProduct = searchProduct
    //  }
      }
      else{
        finalFilteredProduct = products
      }
  }
  else{
    return products
  }
}
   
    
    if(sortType.filter(t=>t==="category")[0] === "category"){
      let a = sortValue.filter(v => v==='men' || v === 'women' || v === 'boys' || v=== 'girls')
      if(a.length>0){
        var categoryProduct = []
        // if(finalFilteredProduct.length>0){
          categoryProduct = finalFilteredProduct.filter(
            product => {
              return product.category&& product.category.filter(single => {
                if(typeof sortValue === 'string'){
                  return single.value === sortValue
  
            }else{
              for (var i=0; i<sortValue.length; i++){
              
                if(single.value === sortValue[i])
                {
                  return true
                }
              }
            }
          
        })[0]})
        finalFilteredProduct = categoryProduct
      // }else{
      //   categoryProduct = products.filter(
      //     product => {
      //       return product.category&& product.category.filter(single => {
      //         if(typeof sortValue === 'string'){
      //           return single.value === sortValue

      //     }else{
      //       for (var i=0; i<sortValue.length; i++){
            
      //         if(single.value === sortValue[i])
      //         {
      //           return true
      //         }
      //       }
      //     }
        
      // })[0]})
      // }
      if(categoryProduct.length>0){
      finalFilteredProduct = categoryProduct
      }
    }
    }

    if (sortType.filter(t=>t==="tag")[0] === "tag") {
      var tagProduct=[]
      tagProduct = finalFilteredProduct.filter(
        product => {
          return product.tag&& product.tag.filter(single => {
            if(typeof sortValue === 'string'){
              return single.value.toLowerCase() === sortValue.toLowerCase()
  
        }else{
          for (var i=0; i<sortValue.length; i++){
          
            if(single.value === sortValue[i])
            {
              return true
            }
          }
        }
      
    })[0]})
  
      finalFilteredProduct = tagProduct
    }

    if(sortType.filter(t=>t==="color")[0] === "color"){
      // let a = sortValue.filter(v => v==='black' || v === 'white' || v === 'red' || v=== 'yellow'|| v === 'blue' || v === 'skyblue' || v=== 'darkblue'|| v === 'pink' || v === 'green' || v=== 'orange'|| v === 'grey')
      let a = sortValue
      if(a.length>0){
        var colorProduct =[]
        // if(finalFilteredProduct.length>0){
          colorProduct = finalFilteredProduct.filter(
            product => {
              for (var i=0; i<sortValue.length; i++){
                if(product.color.value === sortValue[i])
              {
                return true
              }
            }
          
        })
        
    //     }else{
    //     colorProduct = products.filter(
    //       product => {
    //         for (var i=0; i<sortValue.length; i++){
    //           if(product.color === sortValue[i])
    //         {
    //           return true
    //         }
    //       }
        
    //   })
    // }
    if(colorProduct.length>0){
    finalFilteredProduct = colorProduct
    }
  }
    }

    if(sortType.filter(t=>t==="size")[0] === "size"){
      let a = sortValue.filter(v => v==='3XL' || v === '2XL' || v === 'XL' || v=== 'L'|| v === 'M' || v === 'S' || v=== 'XS'|| v === '0-6 months' || v === '6-12 months' || v === '1-2 years' || v === '2-3 years' || v === '3-4 years' || v === '4-5 years' || v === '5-6 years' || v === '6-7 years' || v === '7-8 years'|| v === '8-9 years' || v === '9-10 years' || v === '10-11 years'|| v === '11-12 years' || v === '12-13 years'|| v === '13-14 years' || v === '14-15 years' || v === '15-16 years')
      if(a.length>0){
      // console.log(finalFilteredProduct)
        var sizeProduct =[]
        // if(finalFilteredProduct.length>0){
          sizeProduct = finalFilteredProduct.filter(
            product => {
              product.variation=product.productType === "per unit"? product.unitVariation.filter(v=>v.stock>0) : product.kgVariation.filter(v=>v.stock>0)
              return product.variation && product.variation.filter(single => {
            for (var i=0; i<sortValue.length; i++){
              if(single.size === sortValue[i])
              {
                return true
              }
            }
          
        })[0]})
    //     }
    //     else{
    //       console.log("mmlmldkkd", products)
    //     sizeProduct = products.filter(
    //       product => {
    //         product.variation=product.productType === "per unit"? product.unitVariation : product.kgVariation
    //         return product.variation && product.variation.filter(single => {
    //       for (var i=0; i<sortValue.length; i++){
    //         if(single.size === sortValue[i])
    //         {
    //           return true
    //         }
    //       }
        
    //   })[0]})
      
    // }
   
    // if(sizeProduct.length > 0){
    finalFilteredProduct = sizeProduct
    // }
      }
  }
  
 
    // if(finalFilteredProduct.length>0){
      finalFilteredProduct= getIndividualItemArray(finalFilteredProduct)
      return finalFilteredProduct
    // }else{
    //   return products
    // }
}else{
  return products;
}
};

export const getFilterSortedProducts = (products, sortType, sortValue) => {
  
  if (products && sortType && sortValue) {
    if (sortType === "tag") {
      // console.log(sortValue,products.filter(
      //   product => product.tag.filter(single => single.value === sortValue)[0]))
      return products.filter(
        product => product.tag.filter(single => {
          return single.value === sortValue
        })[0]
      );
    }

    if (sortType === "category") {
      // console.log(sortValue,products.filter(
      //   product => product.tag.filter(single => single.value === sortValue)[0]))
      return products.filter(
        product => product.tag.filter(single => single.value === sortValue)[0]
      );
    }
    
    if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts.sort((a, b) => {
          let x = a.kgVariation.filter(v=>v.stock>0 && v.weight>0)[0]
          let y = b.kgVariation.filter(v=>v.stock>0 && v.weight>0)[0]
          let u = a.unitVariation.filter(v=>v.stock>0 && v.price>0)[0]
          let v =b.unitVariation.filter(v=>v.stock>0 && v.price>0)[0]
          let c = (a.productType=== "per kg"? x? x.price: 0 : u? u.price: 0) 
          let d =  (b.productType=== "per kg"? y? y.price : 0 : v? v.price: 0)
          c = c - c * a.discount/100
          d = d - d * b.discount/100
          return d-c
          // return a.productType === "per unit"? (b.unitVariation[0].price-b.unitVariation[0].price*b.discount/100) - (a.unitVariation[0].price-a.unitVariation[0].price*a.discount/100) : (b.kgVariation.price-b.kgVariation.price*b.discount/100) - (a.kgVariation.price-a.kgVariation.price*a.discount/100)
        });
      }
      if (sortValue === "priceLowToHigh") {
        
        return sortProducts.sort((a, b) => {
          let x = a.kgVariation.filter(v=>v.stock>0 && v.weight>0)[0]
          let y = b.kgVariation.filter(v=>v.stock>0 && v.weight>0)[0]
          let u = a.unitVariation.filter(v=>v.stock>0 && v.price>0)[0]
          let v =b.unitVariation.filter(v=>v.stock>0 && v.price>0)[0]
          let c = (a.productType=== "per kg"? x? x.price: 0 : u? u.price: 0) 
          let d =  (b.productType=== "per kg"? y? y.price : 0 : v? v.price: 0)
          c = c - c * a.discount/100
          d = d - d * b.discount/100
          return c-d
          // return a.productType === "per unit"? (a.unitVariation[0].price-a.unitVariation[0].price*a.discount/100) - (b.unitVariation[0].price-b.unitVariation[0].price*b.discount/100) : (a.kgVariation.price-a.kgVariation.price*a.discount/100) - (b.kgVariation.price-a.kgVariation.price*a.discount/100)
        });
      }
      if(sortValue === "popularity"){
        return sortProducts.sort((a, b) => {
          return b.saleCount - a.saleCount;
        });
      }

      if(sortValue === "new"){
        return sortProducts.sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        });
      }

      if (sortValue === "weightLowToHigh") {
        return sortProducts.sort((a, b) => {
          let c = a.kgVariation.filter(v=>v.stock>0 && v.weight>0)[0]
          let d = b.kgVariation.filter(v=>v.stock>0 && v.weight>0)[0]
          return (c? c.weight: 0 )- (d? d.weight: 0);
        });
      }

      if (sortValue === "weightHighToLow") {
        return sortProducts.sort((a, b) => {
          let c = a.kgVariation.filter(v=>v.stock>0 && v.weight>0)[0]
          let d = b.kgVariation.filter(v=>v.stock>0 && v.weight>0)[0]
          return  (d? d.weight: 0)-(c? c.weight: 0 );        });
      }

    }
    if (sortType === "type") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "per kg") {
        return sortProducts.filter((a) => {
          return a.productType === 'per kg'
        });
      }
      if (sortValue === "per unit") {
        return sortProducts.filter((a) => {
          return a.productType === 'per unit'
        });
      }
       }
       
  }
  return products;
};

 

// get individual element
const getIndividualItemArray = array => {
  if(array && array.length>0){
  let individualItemArray = array.filter(function(v, i, self) {
    return i === self.indexOf(v);
  });
  return individualItemArray;
}
return array
};

// get individual color
const getIndividualItemColor = array => {
  if(array && array.length>0){
  let individualItemArray = array.filter(function(v, i, self) {
    return i === self.indexOf(v.value);
  });
  return individualItemArray;
}
return array
};

// get individual categories
export const getIndividualCategories = products => {
  let productCategories = [];
  products &&
    products.map(product => {
      return (
        product.category &&
        product.category.map(single => {
          return productCategories.push(single.value);
        })
      );
    });
  const individualProductCategories = getIndividualItemArray(productCategories);
  return individualProductCategories;
  // return productCategories
};

// get individual tags
export const getIndividualTags = products => {
  
  let productTags = [];
  if(products&&products.length>0){products &&
    // console.log(12,products)
    products.map(product => {
      return (
        product.tag &&
        product.tag.map(single => {
      
          
          return productTags.push(single.value.toLowerCase());
        })
      );
    });
    
  const individualProductTags = getIndividualItemArray(productTags);
  return individualProductTags;}
  // return productTags
};

// get individual colors
// export const getIndividualColors = products => {
//   let productColors = [];
//   products &&
//     products.map(product => {
//       return (
//         product.variation &&
//         product.variation.map(single => {
//           return productColors.push(single.color);
//         })
//       );
//     });
//   const individualProductColors = getIndividualItemArray(productColors);
//   return individualProductColors;
// };
export const getIndividualColors = products => {
  
  let productColors = [];
  products &&
    products.map(product => {
           product.color && productColors.push(product.color.value);
    });
  const individualProductColors = getIndividualItemArray(productColors).filter(c=>c !== undefined);
  return individualProductColors;
  // return productColors
};

// export const getSearchProduct = products => {
//   let searchProduct = [];
//   products &&
//     products.map(product => {
//       searchProduct.push(product.color);
//     });
//   const individualSearchProduct = getIndividualItemArray(searchProduct);
//   return individualSearchProduct;
// };

// get individual sizes
export const getProductsIndividualSizes = products => {
  let productSizes1 = ['3XL', '2XL','XL', 'L', 'M', 'S', 'XS','0-6 months','6-12 months', '1-2 years', '2-3 years', '3-4 years', '4-5 years', '5-6 years', '6-7 years', '7-8 years', '8-9 years', '9-10 years', '10-11 years', '11-12 years', '12-13 years', '13-14 years', '14-15 years', '15-16 years'];
  let productSizes = []
  products &&
    products.map(product => {
      if(product.productType === 'per kg'){
      return (        
        product.kgVariation &&
        product.kgVariation.filter(v=>v.stock >0 && v.weight>0).map(single => {
          if (single.length>=1){
            return single.map(single => {
              return productSizes.push(single.size);
             
            });
          }else{
              return productSizes.push(single.size);
          }
        })
      );
      }else{
        return (        
          product.unitVariation &&
          product.unitVariation.filter(v=>v.stock >0 && v.price>0).map(single => {
            if (single.length>=1){
            return single.map(single => {
             
                return productSizes.push(single.size);
                
            });
          }else{
              return productSizes.push(single.size);
          }
          })
        );
      }
    });
  const individualProductSizes = getIndividualItemArray(productSizes);
  return productSizes1.filter(value => individualProductSizes.includes(value));
  // return productSizes
};

// get product individual sizes
export const getIndividualSizes = product => {
  let productSizes = [];

  product.variation &&
    product.variation.filter(v=>v.stock>0).map(singleVariation => {
      return (
        singleVariation.size &&
        singleVariation.size.map(singleSize => {
          return productSizes.push(singleSize.name);
        })
      );
    });
  const individualSizes = getIndividualItemArray(productSizes);
  return individualSizes;
};

export const setActiveSort = e => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
  );
  filterButtons.forEach(item => {
    item.classList.remove("active");
  });
  // if(e.currentTarget.classList.contains("active")){
  //   console.log(e.currentTarget.classList.contains("active"))
  //   e.currentTarget.classList.remove("active");
  // }else{
  e.currentTarget.classList.add("active");
  
};

export const setActiveLayout = e => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach(item => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const toggleShopTopFilter = e => {
  const shopTopFilterWrapper = document.querySelector(
    "#product-filter-wrapper"
  );
  shopTopFilterWrapper.classList.toggle("active");
  if (shopTopFilterWrapper.style.height) {
    shopTopFilterWrapper.style.height = null;
  } else {
    shopTopFilterWrapper.style.height =
      shopTopFilterWrapper.scrollHeight + "px";
  }
  e.currentTarget.classList.toggle("active");
};
