import axios from 'axios'
import { logout } from "./userActions";

// export const CREATE_ADMIN_VALUE = "CREATE_ADMIN_VALUE";
export const GET_ADMIN_VALUE_REQUEST = "GET_ADMIN_VALUE_REQUEST";
export const GET_ADMIN_VALUE_FAIL = "GET_ADMIN_VALUE_FAIL";
export const GET_ADMIN_VALUE_SUCCESS = "GET_ADMIN_VALUE_SUCCESS";
export const UPDATE_ADMIN_VALUE_REQUEST = "UPDATE_ADMIN_VALUE_REQUEST";
export const UPDATE_ADMIN_VALUE_FAIL = "UPDATE_ADMIN_VALUE_FAIL";
export const UPDATE_ADMIN_VALUE_SUCCESS = "UPDATE_ADMIN_VALUE_SUCCESS";
export const DELETE_ADMIN_VALUE_REQUEST = "DELETE_ADMIN_VALUE_REQUEST";
export const DELETE_ADMIN_VALUE_SUCCESS = "DELETE_ADMIN_VALUE_SUCCESS";
export const DELETE_ADMIN_VALUE_FAIL = "DELETE_ADMIN_VALUE_FAIL";

//add to cart
export const getAdminValue = () => {
    return async(dispatch) => {  
      try {

        dispatch({
            type: GET_ADMIN_VALUE_REQUEST
        })

        const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/admin`)

        dispatch({
            type:GET_ADMIN_VALUE_SUCCESS,
            payload: data,
          })
          
      } catch (error) {
        dispatch({
            type: GET_ADMIN_VALUE_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
      }         
    }
  
  
      
      
  };


  export const updateAdminValue = (item, addToast) => {
    return async(dispatch, getState) => {  
      try {

        dispatch({
            type: UPDATE_ADMIN_VALUE_REQUEST
        })

        const {
          userLogin: { userInfo },
        } = getState()
    


        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/admin/${item._id}`, item, config)

        if (addToast) {
          addToast("Universal Kg Pricing Updated", { appearance: "success", autoDismiss: true });
        }

        dispatch({
            type:UPDATE_ADMIN_VALUE_SUCCESS,
            payload: data,
          })
          
      } catch (error) {
        dispatch({
            type: UPDATE_ADMIN_VALUE_FAIL,
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
      }         
    }
  
  
      
      
  };