import axios from 'axios'
import { logout } from './userActions'

// import { CART_CLEAR_ITEMS } from '../constants/cartConstants'

import { GET_CART_RESET } from './cartActions';
export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_DELIVER_FAIL = "ORDER_DELIVER_FAIL";
export const ORDER_DELIVER_SUCCESS = "ORDER_DELIVER_SUCCESS";
export const ORDER_DELIVER_REQUEST = "ORDER_DELIVER_REQUEST";
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL";
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_RESET = "ORDER_UPDATE_RESET";
export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";
export const ORDER_DELIVER_RESET = "ORDER_DELIVER_RESET";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";
export const SHIPMENT_LOGIN_REQUEST = "SHIPMENT_LOGIN_REQUEST";
export const SHIPMENT_LOGIN_SUCCESS = "SHIPMENT_LOGIN_SUCCESS";
export const SHIPMENT_LOGIN_FAIL = "SHIPMENT_LOGIN_FAIL";
export const SHIPMENT_LOGIN_RESET = "SHIPMENT_LOGIN_RESET";
export const SHIPMENT_CREATE_ORDER_REQUEST = "SHIPMENT_CREATE_ORDER_REQUEST";
export const SHIPMENT_CREATE_ORDER_SUCCESS = "SHIPMENT_CREATE_ORDER_SUCCESS";
export const SHIPMENT_CREATE_ORDER_FAIL = "SHIPMENT_CREATE_ORDER_FAIL";
export const SHIPMENT_CREATE_ORDER_RESET = "SHIPMENT_CREATE_ORDER_RESET";
export const SHIPMENT_ORDER_DETAIL_SUCCESS = "SHIPMENT_ORDER_DETAIL_SUCCESS";
export const SHIPMENT_ORDER_DETAIL_FAIL = "SHIPMENT_ORDER_DETAIL_FAIL";
export const ALL_SHIPMENT_DETAIL_SUCCESS = "ALL_SHIPMENT_DETAIL_SUCCESS";
export const ALL_SHIPMENT_DETAIL_FAIL = "ALL_SHIPMENT_DETAIL_FAIL";
export const CREATE_PAYMENT_ORDER_REQUEST = "CREATE_PAYMENT_ORDER_REQUEST";
export const CREATE_PAYMENT_ORDER_SUCCESS = "CREATE_PAYMENT_ORDER_SUCCESS";
export const CREATE_PAYMENT_ORDER_FAIL = "CREATE_PAYMENT_ORDER_FAIL";
export const GET_PAYMENT_ORDER_REQUEST = "GET_PAYMENT_ORDER_REQUEST";
export const GET_PAYMENT_ORDER_SUCCESS = "GET_PAYMENT_ORDER_SUCCESS";
export const GET_PAYMENT_ORDER_FAIL = "GET_PAYMENT_ORDER_FAIL";
export const GET_PAYMENT_DETAILS_REQUEST = "GET_PAYMENT_DETAILS_REQUEST";
export const GET_PAYMENT_DETAILS_SUCCESS = "GET_PAYMENT_DETAILS_SUCCESS";
export const GET_PAYMENT_DETAILS_FAIL = "GET_PAYMENT_DETAILS_FAIL";


export const createOrder = (order, addToast) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_RESET,
      payload: data,
    })

    dispatch({
      type: ORDER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/orders`, order, config)

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    })

    if (addToast) {
      addToast("Order Placed", { appearance: "success", autoDismiss: true });
    }
 
    
    localStorage.removeItem('cartItems')
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: message,
    })
  }
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/orders/${id}`, config)

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: message,
    })
  }
}

export const payOrder = (orderId, paymentResult) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: ORDER_PAY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/orders/${orderId}/pay`,
      paymentResult,
      config
    )

    dispatch({
      type: ORDER_PAY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_PAY_FAIL,
      payload: message,
    })
  }
}

export const deliverOrder = (id, status) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELIVER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/orders/${id}/deliver`,
      {status},
      config
    )

    dispatch({
      type: ORDER_DELIVER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_DELIVER_FAIL,
      payload: message,
    })
  }
}

export const listMyOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/orders/myorders`, config)

    dispatch({
      type: ORDER_LIST_MY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload: message,
    })
  }
}

export const listOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/orders`, config)

    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_LIST_FAIL,
      payload: message,
    })
  }
}

export const updateOrders = (order, shipment) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_UPDATE_REQUEST
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/orders/${order._id}/shipping`, shipment, config)

    dispatch({
      type: ORDER_UPDATE_SUCCESS,
      payload: data,
    })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: ORDER_UPDATE_FAIL,
      payload: message,
    })
  }
}

export const shipmentLogin = () => async(dispatch, getState) => {
// var data = JSON.stringify({
//   "email": process.env.REACT_APP_SHIPROCKET_EMAIL,
//   "password": process.env.REACT_APP_SHIPROCKET_PASS
// });

// var config = {
//   method: 'post',
//   url: 'https://apiv2.shiprocket.in/v1/external/auth/login',
//   headers: { 
//     'Content-Type': 'application/json',
//     "Access-Control-Allow-Methods": "POST",
//         "Access-Control-Allow-Origin": "*"
//   },
//   data : data
// };

// axios(config)
// .then(function (response) {
//   dispatch({
//     type: SHIPMENT_LOGIN_SUCCESS,
//     payload: response.data.token
//   })
// })
// .catch(function (error) {
//   dispatch({
//     type: SHIPMENT_LOGIN_FAIL
//   })
// });

try {
  dispatch({
    type: SHIPMENT_LOGIN_REQUEST
  })

  const {
    userLogin: { userInfo },
  } = getState()

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/shipping`, config)

  dispatch({
    type: SHIPMENT_LOGIN_SUCCESS,
    payload: data.token,
  })

} catch (error) {
  const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: SHIPMENT_LOGIN_FAIL,
      payload: message,
    })
}

}

export const createShipmentTrack = (order) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState()

  var orderData = {
    "order_id": order._id,
    "order_date": new Date(`${order.createdAt}`).toISOString().split('T')[0],
    "channel_id": "",
    "comment": "",
    "billing_customer_name": userInfo.name,
    "billing_last_name": "",
    "billing_address": userInfo.shippingAddress[0].address,
    "billing_address_2": "",
    "billing_city": "",
    "billing_pincode": userInfo.shippingAddress[0].zipCode,
    "billing_state": userInfo.shippingAddress[0].state,
    "billing_country": userInfo.shippingAddress[0].country,
    "billing_email": userInfo.email,
    "billing_phone": userInfo.mobile,
    "shipping_is_billing": true,
    "shipping_customer_name": userInfo.name,
    "shipping_address": userInfo.shippingAddress[0].address,
    "shipping_address_2": "",
    "shipping_city": userInfo.shippingAddress[0].zipCode,
    "shipping_pincode": userInfo.shippingAddress[0].zipCode,
    "shipping_country": userInfo.shippingAddress[0].country,
    "shipping_state": userInfo.shippingAddress[0].state,
    "shipping_email": userInfo.email,
    "shipping_phone": userInfo.mobile,
    "order_items": order.orderItems,
    "payment_method": "Prepaid",
    "shipping_charges": 0,
    "giftwrap_charges": 0,
    "transaction_charges": 0,
    "total_discount": 0,
    "sub_total": order.totalPrice,
    "length": 10,
    "breadth": 15,
    "height": 20,
    "weight": 2.5
  }

//   var orderData = {
//     "order_id": "627e48d58992d8f6e2df5345",
//     "order_date": "2022-05-13",
//     "channel_id": "",
//     "comment": "",
//     "billing_customer_name": "Mahesh Devadiga",
//     "billing_last_name": "",
//     "billing_address": "South Street Seaport, 12 Fulton St, New York, NY, 10038, USA",
//     "billing_address_2": "",
//     "billing_city": "100385",
//     "billing_pincode": "100385",
//     "billing_state": "New York",
//     "billing_country": "United States",
//     "billing_email": "mahesd248@gmail.com",
//     "billing_phone": 9090909777,
//     "shipping_is_billing": true,
//     "shipping_customer_name": "Mahesh Devadiga",
//     "shipping_address": "South Street Seaport, 12 Fulton St, New York, NY, 10038, USA",
//     "shipping_address_2": "",
//     "shipping_city": "100385",
//     "shipping_pincode": "100385",
//     "shipping_country": "United States",
//     "shipping_state": "New York",
//     "shipping_email": "mahesd248@gmail.com",
//     "shipping_phone": 9090909777,
//     "order_items": order.orderItems,
//     "payment_method": "Prepaid",
//     "shipping_charges": 0,
//     "giftwrap_charges": 0,
//     "transaction_charges": 0,
//     "total_discount": 0,
//     "sub_total": 560,
//     "length": 10,
//     "breadth": 15,
//     "height": 20,
//     "weight": 2.5
// }

// var orderData = JSON.stringify({
//   "order_id": order._id,
//   "order_date": new Date(`${order.createdAt}`).toISOString().split('T')[0],
//   "channel_id": "",
//   "comment": "Reseller: M/s Goku",
//   "billing_customer_name": userInfo.name,
//     "billing_last_name": "",
//     "billing_address": userInfo.shippingAddress[0].address,
//     "billing_address_2": "",
//     "billing_city": "",
//     "billing_pincode": userInfo.shippingAddress[0].zipCode,
//     "billing_state": userInfo.shippingAddress[0].state,
//     "billing_country": userInfo.shippingAddress[0].country,
//   "billing_email": userInfo.email,
//   "billing_phone": userInfo.mobile,
//   "shipping_is_billing": true,
//   "shipping_customer_name": "",
//   "shipping_last_name": "",
//   "shipping_address": "",
//   "shipping_address_2": "",
//   "shipping_city": userInfo.shippingAddress[0].zipCode,
//     "shipping_pincode": userInfo.shippingAddress[0].zipCode,
//     "shipping_country": userInfo.shippingAddress[0].country,
//     "shipping_state": userInfo.shippingAddress[0].state,
//     "shipping_email": userInfo.email,
//     "shipping_phone": userInfo.mobile,
//   "order_items": [
//     {
//       "name": "Kunai",
//       "sku": "chakra123",
//       "units": 10,
//       "selling_price": "900",
//       "discount": "",
//       "tax": "",
//       "hsn": 441122
//     }
//   ],
//   "payment_method": "Prepaid",
//   "shipping_charges": 0,
//   "giftwrap_charges": 0,
//   "transaction_charges": 0,
//   "total_discount": 0,
//   "sub_total": 9000,
//   "length": 10,
//   "breadth": 15,
//   "height": 20,
//   "weight": 2.5
// });

  const {
    shipmentLogin: { token },
  } = getState()

  
  // var config = {
  //   method: 'post',
  //   url: 'https://apiv2.shiprocket.in/v1/external/orders/create/adhoc',
  //   headers: { 
  //     'Content-Type': 'application/json', 
  //     Authorization: `Bearer ${token}`,
  //   },
  //   data : data
  // };
  
  // axios(config)
  // .then(function (response) {

  //  dispatch({
  //    type: SHIPMENT_CREATE_ORDER_SUCCESS,
  //    payload:response.data
  //  })
  // })
  // .catch(function (error) {

  //   dispatch({
  //     type: SHIPMENT_CREATE_ORDER_FAIL,
  //     payload:error
  //   })
  // });

  try {
    dispatch({
      type: SHIPMENT_CREATE_ORDER_REQUEST
    })
  
  
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
  
    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/shipping/order`,{token, orderData}, config)
  
    dispatch({
      type: SHIPMENT_CREATE_ORDER_SUCCESS,
      payload: data,
    })
  
  } catch (error) {
    const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: SHIPMENT_CREATE_ORDER_FAIL,
        payload: message,
      })
  }

}

export const getShipmentTrack = (shipmentId) => async (dispatch, getState) => {
    const {
      shipmentLogin: { token },
    } = getState()
  
    
    var config = {
      method: 'get',
      url: `https://apiv2.shiprocket.in/v1/external/courier/track/shipment/${shipmentId}`,
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    };
    
    axios(config)
    .then(function (response) {
      dispatch({
        type:SHIPMENT_ORDER_DETAIL_SUCCESS,
        payload:response.data
      })

    })
    .catch(function (error) {

      dispatch({
        type:SHIPMENT_ORDER_DETAIL_FAIL,
        payload:error
      })
    });
  

}

export const getAllShipment = () => async (dispatch, getState) => {

  const {
    shipmentLogin: { token },
  } = getState()

  
  var config = {
    method: 'get',
    url: `https://apiv2.shiprocket.in/v1/external/shipments`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}`
    }
  };
  
  axios(config)
  .then(function (response) {
    dispatch({
      type:ALL_SHIPMENT_DETAIL_SUCCESS,
      payload:response.data
    })

  })
  .catch(function (error) {

    dispatch({
      type:ALL_SHIPMENT_DETAIL_FAIL,
      payload:error
    })
  });


}

export const createPaymentOrder = (totalPrice, id, user) => async (dispatch, getState) => {
  
  try {
    dispatch({
      type: CREATE_PAYMENT_ORDER_REQUEST
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/cashfree`, {totalPrice, id, user}, config)

    dispatch({
      type: CREATE_PAYMENT_ORDER_SUCCESS,
      payload: data,
    })

    // if (addToast) {
    //   addToast("Order Placed", { appearance: "success", autoDismiss: true });
    // }
 
    
    // localStorage.removeItem('cartItems')
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: CREATE_PAYMENT_ORDER_FAIL,
      payload: message,
    })
  }
}

export const getPaymentOrder = (id) => async (dispatch, getState) => {
  
  try {
    dispatch({
      type: GET_PAYMENT_ORDER_REQUEST
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/cashfree/${id}`, config)

    dispatch({
      type: GET_PAYMENT_ORDER_SUCCESS,
      payload: data,
    })

    // if (addToast) {
    //   addToast("Order Placed", { appearance: "success", autoDismiss: true });
    // }
 
    
    // localStorage.removeItem('cartItems')
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: GET_PAYMENT_ORDER_FAIL,
      payload: message,
    })
  }
}

export const getPaymentDetails = (id) => async (dispatch, getState) => {
  
  try {
    dispatch({
      type: GET_PAYMENT_DETAILS_REQUEST
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/cashfree/${id}`, config)

    dispatch({
      type: GET_PAYMENT_DETAILS_SUCCESS,
      payload: data,
    })

    // if (addToast) {
    //   addToast("Order Placed", { appearance: "success", autoDismiss: true });
    // }
 
    
    // localStorage.removeItem('cartItems')
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: GET_PAYMENT_DETAILS_FAIL,
      payload: message,
    })
  }
}

