import React, {useState} from "react";
import { setActiveSort } from "../../helpers/product";

const ShopSearch = ({ search, getSortParams, removeSortParams }) => {
  const [searchP, setSearchP] = useState('')

  const searchHanler = (e) => {
    e.preventDefault()
    if(searchP){
    getSortParams("search", searchP);
    setActiveSort(e);
    }else{
      removeSortParams("search", searchP)
    }
  }

  return (
    <div className="sidebar-widget">
      {/* <h4 className="pro-sidebar-title">Search </h4> */}
      <div className="pro-sidebar-search mb-20">
        <div className="pro-sidebar-search-form" >
          <form onSubmit={(e)=>searchHanler(e)}>
          <input type="text" value = {searchP} placeholder="Search here..." onChange= {(e) => setSearchP(e.target.value)} />
          <button type="submit">
            <i className="pe-7s-search" />
          </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopSearch;
