import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../../helpers/product";


const MenuCart = ({ cartData, currency, deleteFromCart }) => {
  let cartTotalPrice = 0;
  const { addToast } = useToasts();


  
  return (
    <div className="shopping-cart-content">
      {cartData && cartData.length > 0 ? (
        <Fragment>
          <ul>
            {cartData && cartData.map((single, key) => {
              var a = single.productType === "per unit"? single.unitVariation.filter(v => v.size === single.selectedProductSize)[0] : single.kgVariation.filter(v => v.size === single.selectedProductSize)[0]
              const discountedPrice = getDiscountPrice(
                single.productType === "per unit"? a.price : a.price*a.weight/1000,
                single.discount
              );
              
              const finalProductPrice = (
                single.productType === "per unit"? a.price : a.price*a.weight/1000
              ).toFixed(2);
              const finalDiscountedPrice = discountedPrice?(
                discountedPrice
              ).toFixed(2) : null;

              discountedPrice != null
                ? (cartTotalPrice += finalDiscountedPrice * single.quantity)
                : (cartTotalPrice += finalProductPrice * single.quantity);
              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link to={process.env.PUBLIC_URL + "/product/" + single.id}>
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + single.images[0].secureUrl}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={process.env.PUBLIC_URL + "/product/" + single._id}
                      >
                        {" "}
                        {single.name}{" "}
                      </Link>
                    </h4>
                    <h6>Size: {single.selectedProductSize}</h6>
                    <h6>Qty: {single.quantity}</h6>
                    {single.productType === "per kg"?<h6>Weight: {single.kgVariation.filter(v=>v.size === single.selectedProductSize)[0].weight}g</h6>:''}
                    {single.productType === "per unit" && <span>
                      {discountedPrice !== null
                        ? (currency.currencySymbol + (finalDiscountedPrice*single.quantity).toFixed(2)
                        )
                        : (currency.currencySymbol + (finalProductPrice*single.quantity).toFixed(2))}
                    </span>}
                    {single.selectedProductColor &&
                    single.selectedProductSize ? (
                      <div className="cart-item-variation">
                        <span>Color: {single.selectedProductColor}</span>
                        <span>Size: {single.selectedProductSize}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => deleteFromCart(single, addToast)}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              Total :{" "}
              <span className="shop-total">
                {currency.currencySymbol + cartTotalPrice.toFixed(2)}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              view cart
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              checkout
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">No items added to cart</p>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  deleteFromCart: PropTypes.func
};

export default MenuCart;
