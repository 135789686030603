import PropTypes from "prop-types";
import React from "react";
import ProductgridList from "./ProductgridList";

const ShopProducts = ({ products, layout }) => {
  return (
    <div className="shop-bottom-area mt-35">
      <div className={`row ${layout ? layout : ""}`}>
       {products && products.length>0? <ProductgridList products={products} spaceBottomClass="mb-25" /> : <h2 className="d-flex justify-content-center align-items-center mt-5">"No matching product found"</h2> }
      </div>
    </div>
  );
};

ShopProducts.propTypes = {
  layout: PropTypes.string,
  products: PropTypes.array
};

export default ShopProducts;
