import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import { setActiveSort } from "../../helpers/product";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

const ShopCategories1 = ({ categories, getSortParams, removeSortParams}) => {

  const [selectedCategory, setSelectedCategory] = useState([])

  useEffect(() => {
    if(selectedCategory && selectedCategory.length>0){
    getSortParams("category", selectedCategory);
    }
  }, [selectedCategory])

  const categoryHandler = (e, size) => {
    

    if(e.currentTarget.className === `active`){
      e.currentTarget.classList.remove("active")
      removeSortParams("category", size)
      setSelectedCategory(selectedCategory.filter(c => c !== size))
    }else{
      setSelectedCategory([size])
      e.currentTarget.classList.add("active")
    }
  }


  return (
    <div className="sidebar-widget">
      {/* <h4 className="pro-sidebar-title">Categories </h4> */}
      <div className="sidebar-widget-list mt-20">
        {categories && categories.length>0 ? (
          <ul>
              {categories.filter(c => c === "boys" || c === "girls")[0] && <li>
                {/* <div className="sidebar-widget-list-left"> 
                      {" "}
                      <span />Kids{" "}
                  </div> */}
                  <div className="filter-wrapper">
                  <Accordion defaultActiveKey="0">
                    <Card className="single-my-account mb-20">
                      <Card.Header className="panel-heading">
                        <Accordion.Toggle variant="link" eventKey="2" className="sideb">
                          <h3 className="panel-title">
                            Kids
                          </h3>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <div className="">
                          <ul>
                    {categories.filter(c => c === "boys")[0] && <li>
                    <div className="sidebar-widget-list-left">
                      <button 
                        onClick={(e)=>categoryHandler(e, "boys")}
                      >
                        {" "}
                        <span className="checkmark fa fa-check" /> Boys{" "}
                      </button>
                    </div>
                    </li>}
                        {categories.filter(c => c === "girls")[0] && <li>
                      <div className="sidebar-widget-list-left">
                        <button 
                          onClick={(e)=>categoryHandler(e, "girls")}
                        >
                          {" "}
                          <span className="checkmark fa fa-check" /> Girls{" "}
                        </button>
                      </div>
                  </li>}
                  </ul>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  
                </div>
              </li>}
              
          </ul>
        ) : (
          "No categories found"
        )}
      </div>
    </div>
  );
};

ShopCategories1.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func,
  removeSortParams: PropTypes.func
};

export default ShopCategories1;