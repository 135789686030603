// import { FETCH_PRODUCTS_SUCCESS } from "../actions/productActions";
import { 
    SLIDER_UPDATE_REQUEST,
    SLIDER_UPDATE_SUCCESS,
    SLIDER_UPDATE_FAIL,
    SLIDER_UPDATE_RESET,
    SLIDER_FETCH_REQUEST,
    SLIDER_FETCH_SUCCESS,
    SLIDER_FETCH_FAIL,

  } from "../actions/sliderUploadActions";
  
  const initState = {
    loading:true,
    products: [],
    error:{}
  };
  
  // const productReducer = (state = initState, action) => {
  //   if (action.type === FETCH_PRODUCTS_SUCCESS) {
  //     return {
  //       ...state,
  //       products: action.payload
  //     };
  //   }
  
  //   return state;
  // };
  
  // export default productReducer;
  
  export const slidersReducer = (state = { slider: [] }, action) => {
    switch (action.type) {
      case SLIDER_FETCH_REQUEST:
        return { loading: true, slider: [] }
      case SLIDER_FETCH_SUCCESS:
        return {
          loading: false,
          slider: action.payload,
        }
      case SLIDER_FETCH_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  
  
  export const sliderUpdateReducer = (state = { product: {} }, action) => {
    switch (action.type) {
      case SLIDER_UPDATE_REQUEST:
        return { loading: true }
      case SLIDER_UPDATE_SUCCESS:
        return { loading: false, success: true, slider: action.payload }
      case SLIDER_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      case SLIDER_UPDATE_RESET:
          return { success:false }
      default:
        return state
    }
  }
  
  