import React, { Fragment, useState, useEffect} from "react";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useToasts } from "react-toast-notifications";

import { ACCOUNT_VERIFY_RESET, login, passwordReset, accountVerify } from "../../redux/actions/userActions";
import { getCartList } from "../../redux/actions/cartActions";
import { getWishList } from "../../redux/actions/wishlistActions";
import { DELETE_ALL_FROM_WISHLIST } from "../../redux/actions/wishlistActions";
import { fetchProducts } from "../../redux/actions/productActions";


const EmailSentMessage = ({ }) => {
  


  return (
    <Fragment>
      <MetaTags>
        <title>Zmikas | Account Verification</title>
        <meta
          name="description"
          content="Account Verification"
        />
      </MetaTags>
      {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Password Reset
      </BreadcrumbsItem>*/}
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {/* <Breadcrumb />  */}
        <div className="login-register-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                <div className="login-register-wrapper">
                <Tab.Container defaultActiveKey="login">
                    <Nav variant="pills" className="login-register-tab-list">
                      <Nav.Item>
                        <Nav.Link eventKey="login">
                          <h4>Account verification</h4>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="login">
                        <div className="login-form-container">
                          <div className="login-register-form text-center">
                              <h4>Verification email sent to your email Id. Please check the email and verify the account</h4>
                          </div>
                        </div>
                      </Tab.Pane>
                      
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};


export default EmailSentMessage;
