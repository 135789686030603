import PropTypes from "prop-types";
import React, {useEffect, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";

import { logout, updateUserProfile } from "../../redux/actions/userActions";
import { addToWishlist, getWishList } from "../../redux/actions/wishlistActions";
import { getCartList } from "../../redux/actions/cartActions";
// import Wishlist from "../../pages/other/Wishlist";

const IconGroup = ({
  currency,
  cartData,
  wishlistData,
  compareData,
  deleteFromCart,
  iconWhiteClass,
  history,
  products,
  userInfo
}) => {

  const dispatch = useDispatch()
  const ref = useRef(null)

  // const userLogin = useSelector((state) => state.userLogin)
  // const { userInfo } = userLogin


  currency.currencyRate = 1
  currency.currencySymbol = 'Rs. '

  useEffect(() => {
    if(userInfo && userInfo.name){
    dispatch(getCartList())
    setTimeout(()=>{
      dispatch(getWishList())
    }, 500)
  }
  }, [])

  

  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");

    // if (ref.current && !ref.current.contains(e.target)) {
    //   onClickOutside && onClickOutside();
    // }
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  const logoutHandler = () =>{
    dispatch(logout())
  }

  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      {/* <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={e => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div> */}
      <div className="same-style account-setting d-lg-block me-1">
        <button
          className="account-setting-active"
          onClick={e => handleClick(e)}
        >
          <i className="pe-7s-user-female" />
        </button>
        <div className="account-dropdown profile">
            {!userInfo? 
            (<ul>
              <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>Login</Link>
            </li>
            {/* <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                Register
              </Link>
            </li>*/}
            </ul>) :( 
            <ul>
              <li className="capitalize user-name">Hello,<br /> {userInfo.name}<hr/></li>
              <li>
              {userInfo.isAdmin? (
              <Link to={process.env.PUBLIC_URL + "/admin"}>
                Dashboard
              </Link>):(
              <Link to={process.env.PUBLIC_URL + "/my-account"}>
                my account
              </Link>
              )}
            </li>
            <li>
            {!userInfo.isAdmin &&
              <Link to={process.env.PUBLIC_URL + '/myorders'}>Orders</Link>
            }
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + '/'} onClick={()=>logoutHandler()}>Logout</Link>
            </li>
            </ul>
            )}
          
        </div>
      </div>
      {/* <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareData && compareData.length ? compareData.length : 0}
          </span>
        </Link>
      </div> */}
      <div className="same-style header-wishlist me-lg-1">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={e => handleClick(e)}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart
          cartData={cartData}
          currency={currency}
          deleteFromCart={deleteFromCart}
        />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array,
  products: PropTypes.array,
  userInfo: PropTypes.object
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    compareData: state.compareData,
    products: state.productList.products,
    userInfo: state.userLogin.userInfo,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
