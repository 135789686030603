import PropTypes from "prop-types";
import React, { Fragment } from "react";

const ProductRating = ({ ratingValue }) => {
  let rating = [];

  for (let i = 0; i < ratingValue; i++) {
    rating.push(<i className="fa fa-star yellow" key={i}></i>);
  }
  
  for (let i = 0; i < 5-ratingValue; i++) {
    rating.push(<i className="fa fa-star" key={i+ratingValue}></i>);
  }
  return <Fragment></Fragment>;
};

ProductRating.propTypes = {
  ratingValue: PropTypes.number
};

export default ProductRating;
