import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { browserHistory } from "react-router"
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import kgProductsTags from "./pages/collections/KgProductsTag";
import AccountVerification from "./pages/other/AccountVerification";
import EmailSentMessage from "./pages/other/EmailSentMessage";
import PasswordResetEmailSentMessage from "./pages/other/PasswordResetEmailSentMessage";


// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));
const WholeSales = lazy(() => import("./pages/collections/WholeSales"));
const Clothes = lazy(() => import("./pages/collections/Clothes"));
const Collections = lazy(() => import("./pages/collections/Collections"));
const KgProductsTag = lazy(() => import("./pages/collections/KgProductsTag"));
const UnitProductsTag = lazy(() => import("./pages/collections/UnitProductsTag"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

window.addEventListener("beforeunload", () => localStorage.removeItem('storageVariable'));


// other pages
const About = lazy(() => import("./pages/other/About"));
const TermsOfUse = lazy(() => import("./pages/other/TermsOfUse"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Orderlist = lazy(() => import("./pages/other/Orderlist"));
const OrderDetailsView = lazy(() => import("./pages/other/OrderDetailsView"));
const PasswordReset = lazy(() => import("./pages/other/PasswordReset"));
const PasswordResetRequest = lazy(() => import("./pages/other/PasswordResetRequest"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

//admin dashboard
const AdminDashboard = lazy(() => import("./pages/admin/AdminDashboard"));



const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json")
        }
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeFashion}
                />

                {/* Shop pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard/:category"}
                  component={Collections}
                />
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                  component={ShopGridFilter}
                />
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                  component={ShopGridTwoColumn}
                />
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}
                  component={ShopGridNoSidebar}
                />
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                  component={ShopGridFullWidth}
                />
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                  component={ShopGridRightSidebar}
                />
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/shop-list-standard"}
                  component={ShopListStandard}
                />
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/shop-list-full-width"}
                  component={ShopListFullWidth}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-list-two-column"}
                  component={ShopListTwoColumn}
                />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                  component={ProductTabLeft}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                  component={ProductTabRight}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                  component={ProductSticky}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-slider/:id"}
                  component={ProductSlider}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                  component={ProductFixedImage}
                />

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/myorders/:id/:itemId"}
                  component={OrderDetailsView}
                />

            
                {/* Other pages */}
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/terms-of-use"}
                  component={TermsOfUse}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/compare"}
                  component={Compare}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/admin"}
                  component={AdminDashboard}
                />  



                <Route
                exact
                  path={process.env.PUBLIC_URL + "/buy-in-kg"}
                  component={WholeSales}
                />

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/buy-in-unit"}
                  component={Clothes}
                />

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/myorders"}
                  component={Orderlist}
                /> 

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/collections"}
                  component={ShopGridStandard}
                />

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/password-reset"}
                  component={PasswordResetRequest}
                />

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/password-reset/:id/:token"}
                  component={PasswordReset}
                />

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/:category"}
                  component={Collections}
                /> 

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/kg/:category"}
                  component={KgProductsTag}
                /> 

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/unit/:category"}
                  component={UnitProductsTag}
                /> 

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/account-verify/:id/:token"}
                  component={AccountVerification}
                /> 

                <Route
                exact
                  path={process.env.PUBLIC_URL + "/account-verify/message"}
                  component={EmailSentMessage}
                />  
                
                <Route
                exact
                  path={process.env.PUBLIC_URL + "/password-reset/message"}
                  component={PasswordResetEmailSentMessage}
                />

                

                

                

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));