import axios from 'axios'
import { logout } from "./userActions";


export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const RESET_ALL_FROM_CART = "RESET_ALL_FROM_CART";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAIL = "GET_CART_FAIL";
export const GET_CART_RESET = "GET_CART_RESET";

export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAIL = "UPDATE_CART_FAIL";
export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST";

//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductSize
) => {
  return async(dispatch, getState) => {
    if (addToast) {
      addToast("Added To Cart", { appearance: "success", autoDismiss: true });
    }

    
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    var product = {
      ...item,
      quantity: quantityCount,
      selectedProductColor: selectedProductColor
        ? selectedProductColor
        : item.selectedProductColor
        ? item.selectedProductColor
        : null,
      selectedProductSize: selectedProductSize
        ? selectedProductSize
        : item.selectedProductSize
        ? item.selectedProductSize
        : null
    }

    product.price = product.variation.filter(p=> p.size === p.selectedProductSize).price
    
      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/cart`, product, config)
  


    dispatch({
      type: ADD_TO_CART,
      payload: data
    });
  };
};

//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return async(dispatch, getState) => {
    if (addToast) {
      addToast("Item Decremented From Cart", {
        appearance: "warning",
        autoDismiss: true
      });
    }

    const {
    userLogin: { userInfo },
  } = getState()



  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }


  const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/cart/${item._id}`, item, config)


    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};

//decrease from cart
export const increaseQuantity = (item, addToast) => {
  return async(dispatch, getState) => {
    if (addToast) {
      addToast("Item Incremented From Cart", {
        appearance: "success",
        autoDismiss: true
      });
    }

    const {
    userLogin: { userInfo },
  } = getState()

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }


  const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/cart/${item._id}`, item, config)


    dispatch({ type: INCREASE_QUANTITY, payload: item });
  };
};



//update cart
export const updateCartItem = (item, addToast) =>  async(dispatch, getState) => {
    if (addToast) {
      addToast("Added to Cart", {
        appearance: "success",
        autoDismiss: true
      });
    }

    try {
      // dispatch({
      //   type: UPDATE_CART_REQUEST,
      // })

      const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }


    const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/cart/${item._id}`, item, config)


    dispatch({ type: UPDATE_CART_SUCCESS, payload: item });

  } catch (error) {
    dispatch({
      type: UPDATE_CART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
  };


//delete from cart
export const deleteFromCart = (item, addToast) => {
  return async(dispatch, getState) => {
    if (addToast) {
      addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
    }

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/cart/${item._id}`, config)

    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};
//delete all from cart
export const deleteAllFromCart = addToast => {
  return async(dispatch, getState) => {
    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true
      });
    }

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/cart/${userInfo._id}/all`, config)

    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {

  if(item.productType === 'per unit'){
    return item.unitVariation
      .filter(single => single.size === size)[0].stock;
  // if (item.unitVariation[0].stock) {
  //   return item.unitVariation[0].stock;
  // } else {
  //   return item.unitVariation
  //     .filter(single => single.size === size)[0].stock;
  // }
}else{
  return item.kgVariation
  .filter(single => single.size === size)[0].stock
  
  
  // if (item.kgVariation[0].stock) {
  //   return item.kgVariation[0].stock;
  // } else {
  //   console.log(89, item.kgVariation[0].stock)

  //   return item.kgVariation
  //     .filter(single => single.size === size)[0].stock;
  // }
}
};


export const getCartList = () => async (dispatch, getState) => {
  try {

    dispatch({
      type: GET_CART_RESET
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/cart/mycart`, config)

    dispatch({
      type: GET_CART_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch(logout())
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    // if (message === 'Not authorized, token failed') {
      
    // }
    dispatch({
      type: GET_CART_FAIL
    })
  }
}