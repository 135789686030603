import uuid from "uuid/v4";
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART,
  RESET_ALL_FROM_CART,
  GET_CART_SUCCESS,
  GET_CART_FAIL,
  GET_CART_RESET,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAIL
} from "../actions/cartActions";

const initState = [];

const cartReducer = (state = initState, action) => {
  const cartItems = state,
    product = action.payload;

  // if (action.type === ADD_TO_CART) {
  //   // for non variant products
  //   if (product.variation === undefined) {
  //     const cartItem = cartItems.filter(item => item.id === product.id)[0];
  //     if (cartItem === undefined) {
  //       return [
  //         ...cartItems,
  //         {
  //           ...product,
  //           quantity: product.quantity ? product.quantity : 1,
  //           cartItemId: uuid()
  //         }
  //       ];
  //     } else {
  //       return cartItems.map(item =>
  //         item.cartItemId === cartItem.cartItemId
  //           ? {
  //               ...item,
  //               quantity: product.quantity
  //                 ? item.quantity + product.quantity
  //                 : item.quantity + 1
  //             }
  //           : item
  //       );
  //     }
  //     // for variant products
  //   } else {
  //     const cartItem = cartItems.filter(
  //       item =>
  //         item.id === product.id &&
  //         product.selectedProductColor &&
  //         product.selectedProductColor === item.selectedProductColor &&
  //         product.selectedProductSize &&
  //         product.selectedProductSize === item.selectedProductSize &&
  //         (product.cartItemId ? product.cartItemId === item.cartItemId : true)
  //     )[0];

  //     if (cartItem === undefined) {
  //       return [
  //         ...cartItems,
  //         {
  //           ...product,
  //           quantity: product.quantity ? product.quantity : 1,
  //           cartItemId: uuid()
  //         }
  //       ];
  //     } else if (
  //       cartItem !== undefined &&
  //       (cartItem.selectedProductColor !== product.selectedProductColor ||
  //         cartItem.selectedProductSize !== product.selectedProductSize)
  //     ) {
  //       return [
  //         ...cartItems,
  //         {
  //           ...product,
  //           quantity: product.quantity ? product.quantity : 1,
  //           cartItemId: uuid()
  //         }
  //       ];
  //     } else {
  //       return cartItems.map(item =>
  //         item.cartItemId === cartItem.cartItemId
  //           ? {
  //               ...item,
  //               quantity: product.quantity
  //                 ? item.quantity + product.quantity
  //                 : item.quantity + 1,
  //               selectedProductColor: product.selectedProductColor,
  //               selectedProductSize: product.selectedProductSize
  //             }
  //           : item
  //       );
  //     }
  //   }
  // }

  if (action.type === ADD_TO_CART) {
    return [...cartItems, product]
  }

  if (action.type === INCREASE_QUANTITY) {
    return cartItems.map(item =>
      item.cartItemId === product.cartItemId
        ? { ...item, quantity: item.quantity }
        : item
    );
  }

  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 0) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => (cartItem.productId !== product.productId)
        );

      return remainingItems(cartItems, product);
    } else {
    
      return cartItems.map(item =>
        item.cartItemId === product.cartItemId
          ? { ...item, quantity: item.quantity }
          : item
      );
    }
  }

  if (action.type === DELETE_FROM_CART) {
    
    const remainingItems =  cartItems.filter(cartItem => {
      if(cartItem.productId === product.productId){
        return (cartItem.selectedProductSize !== product.selectedProductSize)
      }else{
      return true
    }}
    );
    
    return remainingItems;
  }

  

  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter(item => {
      return false;
    });
  }

  if (action.type === RESET_ALL_FROM_CART) {
    return []
  }

  if (action.type === GET_CART_SUCCESS) {
    return [...product]
  }

  if (action.type === UPDATE_CART_SUCCESS) {
    return [cartItems]
  }
  

  if (action.type === GET_CART_FAIL) {
    return []
  }

  if (action.type === GET_CART_RESET) {
    return []
  }

  if (action.type === UPDATE_CART_FAIL) {
    return [cartItems]
  }

  return state;
};

export default cartReducer;
