import currencyReducer from "./currencyReducer";
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
} from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import { 
  userLoginReducer,
  userListReducer,
  userRegisterReducer, 
  userDetailsReducer, 
  userUpdateProfileReducer,
  passwordResetReducer,
  sendEmailReducer,
  accountVerifyReducer,
  accountVerifyEmailReducer
} from "./userReducer";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderUpdateReducer,
  orderListMyReducer,
  orderListReducer,
  shipmentLoginReducer,
  shipmentOrderReducer,
  getShipmentDetailReducer,
  getAllShipmentReducer,
  createPaymentOrderReducer,
  getPaymentOrderReducer,
  getPaymentDetailsReducer
} from './orderReducer'
import {sliderUpdateReducer, slidersReducer} from './sliderUploadReducer'
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import {adminValueDataReducer} from './adminValueReducer'

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  cartData: cartReducer,
  compareData: compareReducer,
  wishlistData: wishlistReducer,
  userLogin: userLoginReducer,
  userList: userListReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  passwordReset: passwordResetReducer,
  accountVerify: accountVerifyReducer,
  accountVerifyEmail: accountVerifyEmailReducer,
  sendEmail: sendEmailReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
  orderDeliver: orderDeliverReducer,
  orderList: orderListReducer,
  orderUpdate: orderUpdateReducer,
  shipmentLogin: shipmentLoginReducer,
  shipmentOrder: shipmentOrderReducer,
  getShipmentDetail : getShipmentDetailReducer,
  getAllShipment : getAllShipmentReducer,
  createPaymentOrder : createPaymentOrderReducer,
  getPaymentOrder : getPaymentOrderReducer,
  getPaymentDetails : getPaymentDetailsReducer,
  adminValueData: adminValueDataReducer,
  sliderData : slidersReducer,
  sliderUpdate : sliderUpdateReducer
});

export default rootReducer;
