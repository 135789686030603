import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import Swiper from "react-id-swiper";
import {
  getIndividualTags
} from "../../helpers/product2";

const ProductImageGallerySlider = ({ product,products,
  sortedProducts,
  category, 
  getSortParams,
  removeSortParams,
  sortType,
  sortValue, tags }) => {
  const [selectedTags, setSelectedTags] = useState([])
 

  useEffect(() => {
    if(selectedTags){
    getSortParams("tag", selectedTags);
    }
  }, [selectedTags])

  const tagHandler = (e, tag) => {
    // console.log(1,e.target.innerHTML)
    if(e.target.className === `tag-btn active`){
      e.target.className = `tag-btn`
      removeSortParams("tag", tag)
      setSelectedTags(selectedTags.filter(c => c !== tag))
    }else{
      setSelectedTags([...selectedTags, tag])
      e.target.className = `tag-btn active`
    }
  }

  // console.log(tag)
  


  // swiper slider settings
  const gallerySwiperParams = {
    effect:"slide",
    spaceBetween: 15,
    slidesPerView: 3,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <i className="pe-7s-angle-left" />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <i className="pe-7s-angle-right" />
      </button>
    ),
    breakpoints: {
      1024: {
        slidesPerView: 6
      },
      768: {
        slidesPerView: 6
      },
      640: {
        slidesPerView: 4
      },
      320: {
        slidesPerView: 4
      }
    }
  };
  return (
    <div className="product-large-image-wrapper product-large-image-wrapper--slider">
      <Swiper {...gallerySwiperParams}>
        {tags &&
          tags.map((tag, key) => {
            return (
              <div key={key}>
                {/* <a className="single-image tagimage" href={urls[key]}>
                  <img
                    src={process.env.PUBLIC_URL + single}
                    className="img-fluid"
                    alt=""
                  />
                  <p className="tagname">{data[key]}</p>
                </a> */}
                <span key={key} className="tag-btn1">
                    <button
                    className={`${sortValue[0] && sortValue.filter(s => s===tag)[0]? 'tag-btn active' : 'tag-btn' }`}
                      onClick={e => tagHandler(e,tag)}
                    >
                      {tag}
                    </button>
                  </span>
              </div>
            );
          })}
      </Swiper>
    </div>
  );
};

ProductImageGallerySlider.propTypes = {
  product: PropTypes.object
};

export default ProductImageGallerySlider;
