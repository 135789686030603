import {
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_RESET,
    USER_DETAILS_SUCCESS,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_RESET,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_UPDATE_RESET,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_PROFILE_RESET,
    USER_LOGIN_RESET,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_RESET,
    ACCOUNT_VERIFY_REQUEST,
    ACCOUNT_VERIFY_SUCCESS,
    ACCOUNT_VERIFY_FAIL,
    ACCOUNT_VERIFY_RESET,
    ACCOUNT_VERIFY_EMAIL_REQUEST,
    ACCOUNT_VERIFY_EMAIL_SUCCESS,
    ACCOUNT_VERIFY_EMAIL_FAIL,
    ACCOUNT_VERIFY_EMAIL_RESET,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_RESET
  } from '../actions/userActions'

  const initState = {
    products: []
  };
  
  export const userLoginReducer = (state = initState, action) => {
    switch (action.type) {
      case USER_LOGIN_REQUEST:
        return { loading: true }
      case USER_LOGIN_SUCCESS:
        return { loading: false, userInfo: action.payload }
      case USER_LOGIN_FAIL:
        return { loading: false, error: action.payload }
      case USER_LOGIN_RESET:
        return state={}
      case USER_LOGOUT:
        return state={}
      default:
        return state
    }
  }
  
  export const userRegisterReducer = (state = initState, action) => {
    switch (action.type) {
      case USER_REGISTER_REQUEST:
        return { loading: true }
      case USER_REGISTER_SUCCESS:
        return { loading: false, userInfo: action.payload }
      case USER_REGISTER_FAIL:
        return { loading: false, error: action.payload }
      case USER_REGISTER_RESET: 
      return {}
      case USER_LOGOUT:
        return {}
      default:
        return state
    }
  }
  
  export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case USER_DETAILS_REQUEST:
        return { loading: true }
      case USER_DETAILS_SUCCESS:
        return { loading: false, user: action.payload }
      case USER_DETAILS_FAIL:
        return { loading: false, error: action.payload }
      case USER_DETAILS_RESET:
        return { user: {} }
      default:
        return state
    }
  }
  
  export const userUpdateProfileReducer = (state = initState, action) => {
    switch (action.type) {
      case USER_UPDATE_PROFILE_REQUEST:
        return { loading: true }
      case USER_UPDATE_PROFILE_SUCCESS:
        return { loading: false, success: true, userInfo: action.payload }
      case USER_UPDATE_PROFILE_FAIL:
        return { loading: false, error: action.payload }
      case USER_UPDATE_PROFILE_RESET:
        return {}
      default:
        return state
    }
  }
  
  export const userListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
      case USER_LIST_REQUEST:
        return { loading: true }
      case USER_LIST_SUCCESS:
        return { loading: false, users: action.payload }
      case USER_LIST_FAIL:
        return { loading: false, error: action.payload }
      case USER_LIST_RESET:
        return { users: [] }
      default:
        return state
    }
  }
  
  export const userDeleteReducer = (state = initState, action) => {
    switch (action.type) {
      case USER_DELETE_REQUEST:
        return { loading: true }
      case USER_DELETE_SUCCESS:
        return { loading: false, success: true }
      case USER_DELETE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
  
  export const userUpdateReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case USER_UPDATE_REQUEST:
        return { loading: true }
      case USER_UPDATE_SUCCESS:
        return { loading: false, success: true }
      case USER_UPDATE_FAIL:
        return { loading: false, error: action.payload }
      case USER_UPDATE_RESET:
        return {
          user: {},
        }
      default:
        return state
    }
  }

  export const passwordResetReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case PASSWORD_RESET_REQUEST:
        return { loading: true }
      case PASSWORD_RESET_SUCCESS:
        return { loading: false, success: true }
      case PASSWORD_RESET_FAIL:
        return { loading: false, error: action.payload }
      case PASSWORD_RESET_RESET:
          return {success: false }
      default:
        return state
    }
  }

  export const accountVerifyReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case ACCOUNT_VERIFY_REQUEST:
        return { loading: true }
      case ACCOUNT_VERIFY_SUCCESS:
        return { loading: false, success: true }
      case ACCOUNT_VERIFY_FAIL:
        return { loading: false, error: action.payload }
      case ACCOUNT_VERIFY_RESET:
          return {success: false }
      default:
        return state
    }
  }

  export const accountVerifyEmailReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case ACCOUNT_VERIFY_EMAIL_REQUEST:
        return { loading: true }
      case ACCOUNT_VERIFY_EMAIL_SUCCESS:
        return { loading: false, success: true }
      case ACCOUNT_VERIFY_EMAIL_FAIL:
        return { loading: false, error: action.payload }
      case ACCOUNT_VERIFY_EMAIL_RESET:
          return {success: false }
      default:
        return state
    }
  }


  export const sendEmailReducer = (state = { user: {} }, action) => {
    switch (action.type) {
      case SEND_EMAIL_SUCCESS:
        return { success: true }
      case SEND_EMAIL_RESET:
          return { success: false }
      default:
        return state
    }
  }
  