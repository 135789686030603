import axios from "axios";
import { logout } from "./userActions";
import { USER_LOGIN_SUCCESS } from "./userActions";

export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST";
export const ADD_TO_WISHLIST_SUCCESS = "ADD_TO_WISHLIST_SUCCESS";
export const ADD_TO_WISHLIST_FAIL = "ADD_TO_WISHLIST_FAIL";
export const ADD_TO_WISHLIST_RESET = "ADD_TO_WISHLIST_RESET";
export const GET_WISHLIST_REQUEST = "GET_WISHLIST_REQUEST";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const GET_WISHLIST_FAIL = "GET_WISHLIST_FAIL";
export const GET_WISHLIST_RESET = "GET_WISHLIST_RESET"


export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";


export const addToWishlist = (item, addToast) => {
  return async(dispatch, getState)=> {
    if (addToast) {
      addToast("Added To Wishlist", {
        appearance: "success",
        autoDismiss: true
      });
    }

   

    try {
      
        const {
          userLogin: { userInfo },
        } = getState()
        
        if(userInfo){
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
        
      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/wishlist`, item, config)
        }

      dispatch({ type: ADD_TO_WISHLIST, payload: item });
      
    } catch (error) {
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
    }


  };
};

// delete from wishlist
export const deleteFromWishlist = (item, addToast) => {
  return async(dispatch, getState) => {
    if (addToast) {
      addToast("Removed From Wishlist", {
        appearance: "error",
        autoDismiss: true
      });
    }
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/wishlist/${item._id}`, config)

    dispatch({ type: DELETE_FROM_WISHLIST, payload: item });
  };
};

//delete all from wishlist
export const deleteAllFromWishlist = addToast => {
  return async(dispatch, getState) => {
    if (addToast) {
      addToast("Removed All From Wishlist", {
        appearance: "error",
        autoDismiss: true
      });
    }

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/wishlist/${userInfo._id}/all`, config)

    dispatch({ type: DELETE_ALL_FROM_WISHLIST });
  };
};


export const getWishList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_WISHLIST_RESET
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/wishlist/mywishlist`, config)

    dispatch({
      type: GET_WISHLIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    // if (message === 'Not authorized, token failed') {
      dispatch(logout())
    // }
    dispatch({
      type: GET_WISHLIST_FAIL,
      payload: message,
    })
  }
}
