import {
    GET_ADMIN_VALUE_FAIL,
    GET_ADMIN_VALUE_REQUEST,
    GET_ADMIN_VALUE_SUCCESS,
    UPDATE_ADMIN_VALUE_FAIL,
    UPDATE_ADMIN_VALUE_REQUEST,
    UPDATE_ADMIN_VALUE_SUCCESS

} from '../actions/adminValueActions'

const initState = {
    loading:true,
    adminValue: [],
    error:{}
  };


  export const adminValueDataReducer = (state = { adminValue: [] }, action) => {
    switch (action.type) {
      case GET_ADMIN_VALUE_REQUEST:
        return { loading: true, adminValue: [] }
      case GET_ADMIN_VALUE_SUCCESS:
        return {
          loading: false,
          adminValue: action.payload.adminValue,
        }
      case GET_ADMIN_VALUE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

  export const updatedAdminValueReducer = (state = { adminValue: [] }, action) => {
    switch (action.type) {
      case UPDATE_ADMIN_VALUE_REQUEST:
        return { loading: true, adminValue: [] }
      case UPDATE_ADMIN_VALUE_SUCCESS:
        return {
          loading: false,
          adminValue: action.payload.adminValue,
        }
      case UPDATE_ADMIN_VALUE_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }