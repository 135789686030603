 import {
  ADD_TO_WISHLIST,
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST_SUCCESS,
  ADD_TO_WISHLIST_FAIL,
  GET_WISHLIST_SUCCESS,
  GET_WISHLIST_FAIL,
  ADD_TO_WISHLIST_RESET,
  DELETE_FROM_WISHLIST,
  DELETE_ALL_FROM_WISHLIST,
  GET_WISHLIST_REQUEST
} from "../actions/wishlistActions";

const initState = []

const wishlistReducer = (state = initState, action) => {
  var wishlistItems = state,
    product = action.payload;


    if (action.type === GET_WISHLIST_SUCCESS) {
      return product
    }

  if (action.type === ADD_TO_WISHLIST) {
    const wishlistItem = wishlistItems.filter(
      item => item._id === product._id
    )[0];
    if (wishlistItem === undefined) {
      return [...wishlistItems, product];
    } else {
      return wishlistItems;
    }
  }

  if (action.type === DELETE_FROM_WISHLIST) {
    const remainingItems = (wishlistItems, product) =>
      wishlistItems.filter(wishlistItem => wishlistItem.productId !== product.productId);
      console.log("wishlist", wishlistItems, product)
    return remainingItems(wishlistItems, product);
  }

  if (action.type === DELETE_ALL_FROM_WISHLIST) {
    return wishlistItems.filter(item => {
      return false;
    });
  }

  if (action.type === ADD_TO_WISHLIST_RESET) {
    return []
  }

  // if (action.type === GET_WISHLIST_SUCCESS) {
  //   console.log(product)
  // }

  if (action.type === GET_WISHLIST_FAIL) {
    return []
  }

  return wishlistItems;
};




// const wishlistReducer = (state = initState, action) => {
//   switch(action.type){
//     case ADD_TO_WISHLIST_REQUEST:
//       return { loading: true }
//     case ADD_TO_WISHLIST_SUCCESS:
//         return { loading: false, wishlist: action.payload  }
//     case ADD_TO_WISHLIST_FAIL:
//           return { loading: false, error:action.payload }
//   }
// }

export default wishlistReducer;
