import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import { setActiveSort } from "../../helpers/product";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

const ShopSize = ({ sizes, getSortParams, removeSortParams }) => {

  const [selectedSizes, setSelectedSizes] = useState([])

  useEffect(() => {
    if(selectedSizes && selectedSizes.length>0){
    getSortParams("size", selectedSizes);
    }
  }, [selectedSizes])

  const sizeHandler = (e, size) => {
    if(e.currentTarget.className === `text-uppercase active`){
      e.currentTarget.classList.remove("active")
      removeSortParams("size", size)
      setSelectedSizes(selectedSizes.filter(c => c !== size))
    }else{
      setSelectedSizes([size])
      e.currentTarget.classList.add("active")
    }
  }
  

  return (
    <>
    <div className="sidebar-widget mt-20">
      {/* <h4 className="pro-sidebar-title"> Adult Size</h4> */}
      <div className="sidebar-widget-list mt-20">
        {sizes ? (
          <div className="filter-wrapper">
          <Accordion defaultActiveKey="0">
            <Card className="single-my-account mb-20">
              <Card.Header className="panel-heading">
                <Accordion.Toggle variant="link" eventKey="2">
                  <h3 className="panel-title">
                  Adults Size
                  </h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <div className="">
                  <ul>

            {sizes.map((size, key) => {
              return (
                (size === '3XL' || size === '2XL' || size === 'XL' || size === 'L' || size === 'M' || size === 'S' || size === 'XS') &&
                <li key={key}>
                  <div className="sidebar-widget-list-left">
                    <button
                      className="text-uppercase"
                      onClick={e => sizeHandler(e, size)}
                    >
                      {" "}
                      <span className="checkmark fa fa-check" />
                      {size}{" "}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
          
        ) : (
          "No sizes found"
        )}
      </div>
    </div>
    </>
  );
};

ShopSize.propTypes = {
  getSortParams: PropTypes.func,
  sizes: PropTypes.array,
  removeSortParams: PropTypes.func
};

export default ShopSize;