import axios from 'axios'
import { ADD_TO_WISHLIST_RESET } from './wishlistActions';
import { RESET_ALL_FROM_CART } from './cartActions';

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";
export const USER_LOGOUT = "USER_LOGIN_LOGOUT";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_RESET = "USER_LIST_RESET";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_RESET = "PASSWORD_RESET_RESET";
export const ACCOUNT_VERIFY_SUCCESS = "ACCOUNT_VERIFY_SUCCESS";
export const ACCOUNT_VERIFY_FAIL = "ACCOUNT_VERIFY_FAIL";
export const ACCOUNT_VERIFY_REQUEST = "ACCOUNT_VERIFY_REQUEST";
export const ACCOUNT_VERIFY_RESET = "ACCOUNT_VERIFY_RESET";
export const ACCOUNT_VERIFY_EMAIL_SUCCESS = "ACCOUNT_VERIFY_EMAIL_SUCCESS";
export const ACCOUNT_VERIFY_EMAIL_FAIL = "ACCOUNT_VERIFY_EMAIL_FAIL";
export const ACCOUNT_VERIFY_EMAIL_REQUEST = "ACCOUNT_VERIFY_EMAIL_REQUEST";
export const ACCOUNT_VERIFY_EMAIL_RESET = "ACCOUNT_VERIFY_EMAIL_RESET";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_RESET = "SEND_EMAIL_RESET";


export const login = (email, password, remember) => async (dispatch) => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      })
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
  
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/users/login`,
        { email, password },
        config
      )

  
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })

      if(remember && data.verified){
      localStorage.setItem('userInfo', JSON.stringify(data))
      }
    
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response.data
      })
    }
  }
  
  export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('cartData')
    localStorage.removeItem('shippingAddress')
    localStorage.removeItem('paymentMethod')
    localStorage.removeItem('wishlistData')
    localStorage.removeItem('orderListMy')
    localStorage.removeItem('shipmentLogin')
    dispatch({ type: USER_LOGOUT })
    dispatch({ type: USER_DETAILS_RESET })
    // dispatch({ type: ORDER_LIST_MY_RESET })
    dispatch({ type: USER_LIST_RESET })
    dispatch({type : ADD_TO_WISHLIST_RESET})
    dispatch({type : RESET_ALL_FROM_CART})
  }
  
  export const register = (name, email, password, mobile, isAdmin, shippingAddress) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      })
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
  
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/users`,
        { name, email, password, mobile, isAdmin, shippingAddress },
        config
      )
  
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      })

    //   if(!isAdmin && data.verified){
  
    //   dispatch({
    //     type: USER_LOGIN_SUCCESS,
    //     payload: data,
    //   })
  
    //   localStorage.setItem('userInfo', JSON.stringify(data))
    // }
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response.data
      })
    }
  }
  
  export const getUserDetails = (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_DETAILS_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/users/${id}`, config)
  
      dispatch({
        type: USER_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: USER_DETAILS_FAIL,
        payload: message,
      })
    }
  }
  
  export const updateUserProfile = (user, addToast) => async (dispatch, getState) => {
    try {
      
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/users/profile`, user, config)

      if (addToast) {
        addToast("User Profile Updated", { appearance: "success", autoDismiss: true });
      }
  
      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data,
      })
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })
      localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload: message,
      })
    }
  }
  
  export const listUsers = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/users`, config)
  
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: USER_LIST_FAIL,
        payload: message,
      })
    }
  }
  
  export const deleteUser = (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_DELETE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/users/${id}`, config)
  
      dispatch({ type: USER_DELETE_SUCCESS })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: USER_DELETE_FAIL,
        payload: message,
      })
    }
  }
  
  export const updateUser = (user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      })
  
      const {
        userLogin: { userInfo },
      } = getState()
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
  
      const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/users/${user._id}`, user, config)
  
      dispatch({ type: USER_UPDATE_SUCCESS })
  
      dispatch({ type: USER_DETAILS_SUCCESS, payload: data })
  
      dispatch({ type: USER_DETAILS_RESET })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: message,
      })
    }
  }

  export const passwordReset = (id, token, password, addToast) => async (dispatch) => {
    try {
      dispatch({
        type: PASSWORD_RESET_REQUEST,
      })
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      
  
      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/passwordreset/${id}/${token}`, {"password": password}, config)

      if (addToast) {
        addToast("Password Reset", {
          appearance: "success",
          autoDismiss: true
        });
      }
  
      dispatch({ type: PASSWORD_RESET_SUCCESS })
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: PASSWORD_RESET_FAIL,
        payload: message,
      })
    }
  }

  export const accountVerify = (id, token, addToast) => async (dispatch) => {
    try {
      dispatch({
        type: ACCOUNT_VERIFY_REQUEST,
      })
  
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      
  
      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/account-verify/${id}/${token}`, config)

      if (addToast) {
        addToast("Account Verified", {
          appearance: "success",
          autoDismiss: true
        });
      }
  
      dispatch({ type: ACCOUNT_VERIFY_SUCCESS })
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: ACCOUNT_VERIFY_FAIL,
        payload: message,
      })
    }
  }

  export const accountVerifyEmail = (email, addToast) => async (dispatch) => {
    try {   
      dispatch({
        type: ACCOUNT_VERIFY_EMAIL_REQUEST,
      })
      
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
  
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/account-verify`, {email}, config)

      if (addToast) {
        addToast("Please check your email to verify your account", {
          appearance: "success",
          autoDismiss: true
        });
      }

      dispatch({
        type: ACCOUNT_VERIFY_EMAIL_SUCCESS,
      })
  
      
  
    } catch (error) {
      dispatch({
        type: ACCOUNT_VERIFY_EMAIL_FAIL,
      })
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
     console.log(message)
    }
  }


  export const sendEmail = (name, email, subject, msg, addToast) => async (dispatch) => {
    try {

      if (addToast) {
        addToast("Message Sent", { appearance: "success", autoDismiss: true });
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/message`, {name, email, subject, msg}, config)

      dispatch({
        type: SEND_EMAIL_SUCCESS
      })

    } catch (error) {
      console.log(error)
    }
  }
  
  