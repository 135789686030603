import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import { setActiveLayout } from "../../helpers/product2";
import ShopTag from "../../components/product/ShopTag";
import {
  getIndividualTags
} from "../../helpers/product2";
import ProductImageGallerySlider from "./ProductImageGallerySlider";
// import { Aggregate } from "mongoose";
import {Swiper, Slide} from "react-dynamic-swiper"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ShopTopAction = ({
  getLayout,
  getFilterSortParams,
  productCount,
  sortedProductCount,
  products,
  sortedProducts,
  category, 
  getSortParams,
  removeSortParams,
  sortType,
  sortValue
}) => {

  const tags = getIndividualTags(sortedProducts? sortedProducts:products);
  const [selectedTags, setSelectedTags] = useState([])

  const [tagArray, setTagArray] = useState([])

  useEffect(() => {
    if(selectedTags){
    getSortParams("tag", selectedTags);
    }
  }, [selectedTags])

  // useEffect(()=>{
    
  //     setTagArray(tags)
    
  // },[tags])



  // useEffect(()=>{
    
  // },[tags])


  const tagHandler = (e, tag) => {
    // console.log(1,e.target.innerHTML)
    if(e.target.className === `tag-btn active`){
      e.target.className = `tag-btn`
      removeSortParams("tag", tag)
      setSelectedTags(selectedTags.filter(c => c !== tag))
    }else{
      setSelectedTags([...selectedTags, tag])
      e.target.className = `tag-btn active`
    }
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }
    ]
  };

  return (
    <>
    <div className="shop-top-bar mb-15">
      <div className="select-shoing-wrap">
        <div className="shop-select">
          <select
            onChange={e => getFilterSortParams("filterSort", e.target.value)}
          >
            <option value="default">Default</option>
            <option value="new">New</option>
            <option value="popularity">Popularity</option>
            { products.filter(p=>p.productType === 'per unit')[0]?

            (<>
            <option value="priceHighToLow">Price - High to Low</option>
            <option value="priceLowToHigh">Price - Low to High</option>
            </>)
            :
              (<>
            <option value="weightHighToLow">Weight - High to Low</option>
            <option value="weightLowToHigh">Weight - Low to High</option>
            </>)
            }   
          </select>
        </div>
        {/* <p>
          Showing {sortedProductCount} of {productCount} result
        </p> */}
         <div className="tag-section">
          
        
              </div>
              <div className="product-tags">
             {/* {tags && <ProductImageGallerySlider sortValue={sortValue} products={products}sortedProducts ={sortedProducts}
  category ={category}
  getSortParams ={getSortParams}
  removeSortParams ={removeSortParams}
  sortType={sortType}
  tags={tags} />} */}
  </div>
      </div> 

      
  

      <div className="shop-tab">
        <button
          onClick={e => {
            getLayout("grid two-column");
            setActiveLayout(e);
          }}
        >
          <i className="fa fa-th-large" />
        </button>
        <button
          onClick={e => {
            getLayout("grid three-column");
            setActiveLayout(e);
          }}
        >
          <i className="fa fa-th" />
        </button>
        {/* <button
          onClick={e => {
            getLayout("list");
            setActiveLayout(e);
          }}
        >
          <i className="fa fa-list-ul" />
        </button> */}
      </div>
    </div>
    <div className="mx-3">
     {tags && <Slider {...settings}>
              {( ( tags.length>0)) && 
               tags.map((tag, key) => {
                return (
                  <span key={key} className="tag-btn1">
                    <button
                    className={`${sortValue[0] && sortValue.filter(s => s===tag)[0]? 'tag-btn active' : 'tag-btn' }`}
                      onClick={e => tagHandler(e,tag)}
                    >
                      {tag}
                    </button>
                  </span>
                );
              })
              }
              </Slider>}
    </div>
    </>
  );
};

ShopTopAction.propTypes = {
  getFilterSortParams: PropTypes.func,
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number,
  sortedProducts: PropTypes.array

};

export default ShopTopAction;
