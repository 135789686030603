import React, { Fragment, useState, useEffect} from "react";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useToasts } from "react-toast-notifications";

import { ACCOUNT_VERIFY_RESET, accountVerify } from "../../redux/actions/userActions";



const AccountVerification = ({ location, history, match }) => {
  const id = match.params.id
  const token = match.params.token

  const dispatch = useDispatch()

  const AccountVerify = useSelector((state) => state.accountVerify)
  const { loading, error, success } = AccountVerify

  const { addToast } = useToasts();

  useEffect(()=>{
    if(success){
      history.push('/login-register')
      dispatch({
        type: ACCOUNT_VERIFY_RESET
      })
    }else{
      if(!loading){
        history.push('/')
      }
    }
  }, [success])

  useEffect(()=>{
    if(id, token){
      dispatch(accountVerify(id, token, addToast))
    }
  },[id, token])


  return (
    <Fragment>
      <MetaTags>
        <title>Zmikas | Account Verification</title>
        <meta
          name="description"
          content="Account Verification"
        />
      </MetaTags>
      {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Password Reset
      </BreadcrumbsItem>*/}
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {/* <Breadcrumb />  */}
        <div className="login-register-area pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                <div className="login-register-wrapper">
                <Tab.Container defaultActiveKey="login">
                    <Nav variant="pills" className="login-register-tab-list">
                      <Nav.Item>
                        <Nav.Link eventKey="login">
                          <h4>Account verification</h4>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="login">
                        <div className="login-form-container">
                          <div className="login-register-form text-center">
                              <h4>Your account has been verified</h4>
                          </div>
                        </div>
                      </Tab.Pane>
                      
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};


export default AccountVerification;
