import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import { setActiveSort } from "../../helpers/product";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

const ShopColor = ({ colors, getSortParams, removeSortParams }) => {

  const [selectedColors, setSelectedColors] = useState([])

  useEffect(() => {
    if(selectedColors && selectedColors.length>0){
    getSortParams("color", selectedColors);
    }
  }, [selectedColors])

  const colorHandler = (e, color) => {
    if(e.currentTarget.className === `colorbox me-1 btn-tag selected`){
      // e.target.className = `colorbox me-1`
      e.target.className = `colorbox me-1 btn-tag`
      // e.currentTarget.classList.remove = `selected`
      removeSortParams("color", color)
      setSelectedColors(selectedColors.filter(c => c !== color))
    }else{
      setSelectedColors([color])
      e.target.className = `colorbox me-1 btn-tag selected`
      // e.currentTarget.classList.add = `selected`
    }
  }

  
  return (
    <div className="sidebar-widget mt-20">
      {/* <h4 className="pro-sidebar-title">Color </h4> */}
      <div className="filter-wrapper">
                  <Accordion defaultActiveKey="0">
                    <Card className="single-my-account mb-20">
                      <Card.Header className="panel-heading">
                        <Accordion.Toggle variant="link" eventKey="2">
                          <h3 className="panel-title">
                          Color
                          </h3>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <div className="">
                          {colors.length>0 ?(<div className="ct">
        {/* {
          colors && colors.splice(0,50).map((color, i) => (
            <span key={i}  onClick={(e)=>colorHandler(e, color)}>
            <span  className={`colorbox me-1`} style={{backgroundColor: color, padding:"5px 15px", cursor: "pointer"}}></span></span>
          ))
        } */}
        {colors.slice(0,50).map((color, key) => {
              return (
                <li key={key} style={{listStyle: "none"}}>
                  <button
                  className="colorbox me-1 btn-tag"
                    onClick={e => colorHandler(e,color)}
                    style={{backgroundColor: color, padding:"5px 15px", cursor: "pointer"}}
                  >
                    
                  </button>
                </li>
              );
            })}
      </div>):(
        "No colors found"
      )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
     
    </div>
  );
};

ShopColor.propTypes = {
  colors: PropTypes.array,
  getSortParams: PropTypes.func,
  removeSortParams: PropTypes.func
};

export default ShopColor;
