import {
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    ORDER_PAY_REQUEST,
    ORDER_PAY_FAIL,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_RESET,
    ORDER_LIST_MY_REQUEST,
    ORDER_LIST_MY_SUCCESS,
    ORDER_LIST_MY_FAIL,
    ORDER_LIST_MY_RESET,
    ORDER_LIST_FAIL,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_REQUEST,
    ORDER_DELIVER_FAIL,
    ORDER_DELIVER_SUCCESS,
    ORDER_DELIVER_REQUEST,
    ORDER_DELIVER_RESET,
    ORDER_CREATE_RESET,
    ORDER_UPDATE_REQUEST,
    ORDER_UPDATE_SUCCESS,
    ORDER_UPDATE_RESET,
    ORDER_UPDATE_FAIL,
    SHIPMENT_LOGIN_REQUEST,
    SHIPMENT_LOGIN_FAIL,
    SHIPMENT_LOGIN_RESET,
    SHIPMENT_LOGIN_SUCCESS,
    SHIPMENT_CREATE_ORDER_FAIL,
    SHIPMENT_CREATE_ORDER_RESET,
    SHIPMENT_CREATE_ORDER_SUCCESS,
    SHIPMENT_ORDER_DETAIL_SUCCESS,
    SHIPMENT_ORDER_DETAIL_FAIL,
    ALL_SHIPMENT_DETAIL_SUCCESS,
    ALL_SHIPMENT_DETAIL_FAIL,
    GET_PAYMENT_DETAILS_REQUEST,
    GET_PAYMENT_DETAILS_SUCCESS,
    GET_PAYMENT_DETAILS_FAIL,
    GET_PAYMENT_ORDER_REQUEST,
    GET_PAYMENT_ORDER_SUCCESS,
    GET_PAYMENT_ORDER_FAIL,
    CREATE_PAYMENT_ORDER_REQUEST,
    CREATE_PAYMENT_ORDER_SUCCESS,
    CREATE_PAYMENT_ORDER_FAIL
  } from '../actions/orderActions'
  
  export const orderCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case ORDER_CREATE_REQUEST:
        return {
          loading: true,
        }
      case ORDER_CREATE_SUCCESS:
        return {
          loading: false,
          success: true,
          order: action.payload,
        }
      case ORDER_CREATE_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case ORDER_CREATE_RESET:
        return {}
      default:
        return state
    }
  }

  export const orderUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case ORDER_UPDATE_REQUEST:
        return {
          loading: true,
        }
      case ORDER_UPDATE_SUCCESS:
        return {
          loading: false,
          success: true,
          order: action.payload,
        }
      case ORDER_UPDATE_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case ORDER_UPDATE_RESET:
        return {}
      default:
        return state
    }
  }
  
  export const orderDetailsReducer = (
    state = { loading: true, orderItems: [], shippingAddress: {} },
    action
  ) => {
    switch (action.type) {
      case ORDER_DETAILS_REQUEST:
        return {
          ...state,
          loading: true,
        }
      case ORDER_DETAILS_SUCCESS:
        return {
          loading: false,
          order: action.payload,
        }
      case ORDER_DETAILS_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      default:
        return state
    }
  }
  
  export const orderPayReducer = (state = {}, action) => {
    switch (action.type) {
      case ORDER_PAY_REQUEST:
        return {
          loading: true,
        }
      case ORDER_PAY_SUCCESS:
        return {
          loading: false,
          success: true,
        }
      case ORDER_PAY_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case ORDER_PAY_RESET:
        return {}
      default:
        return state
    }
  }
  
  export const orderDeliverReducer = (state = {}, action) => {
    switch (action.type) {
      case ORDER_DELIVER_REQUEST:
        return {
          loading: true,
        }
      case ORDER_DELIVER_SUCCESS:
        return {
          loading: false,
          success: true,
        }
      case ORDER_DELIVER_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case ORDER_DELIVER_RESET:
        return {}
      default:
        return state
    }
  }
  
  export const orderListMyReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case ORDER_LIST_MY_REQUEST:
        return {
          loading: true,
        }
      case ORDER_LIST_MY_SUCCESS:
        return {
          loading: false,
          orders: action.payload,
        }
      case ORDER_LIST_MY_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case ORDER_LIST_MY_RESET:
        return { orders: [] }
      default:
        return state
    }
  }
  
  export const orderListReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case ORDER_LIST_REQUEST:
        return {
          loading: true,
        }
      case ORDER_LIST_SUCCESS:
        return {
          loading: false,
          orders: action.payload,
        }
      case ORDER_LIST_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      default:
        return state
    }
  }

  export const shipmentLoginReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case SHIPMENT_LOGIN_REQUEST:
        return {
          loading: true,
        }
      case SHIPMENT_LOGIN_SUCCESS:
        return {
          loading: false,
          token: action.payload
        }
      case SHIPMENT_LOGIN_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case SHIPMENT_LOGIN_RESET:
        return {}
      default:
        return state
    }
  }


  export const shipmentOrderReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case SHIPMENT_CREATE_ORDER_SUCCESS:
        return {
          loading: false,
          shipment: action.payload
        }
      case SHIPMENT_CREATE_ORDER_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      case SHIPMENT_CREATE_ORDER_RESET:
        return {}
      default:
        return state
    }
  }

  export const getShipmentDetailReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case SHIPMENT_ORDER_DETAIL_SUCCESS:
        return {
          loading: false,
          shipment: action.payload
        }
      case SHIPMENT_ORDER_DETAIL_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      default:
        return state
    }
  }

  export const getAllShipmentReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case ALL_SHIPMENT_DETAIL_SUCCESS:
        return {
          loading: false,
          shipment: action.payload
        }
      case ALL_SHIPMENT_DETAIL_FAIL:
        return {
          loading: false,
          error: action.payload,
        }
      default:
        return state
    }
  }

  export const createPaymentOrderReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case CREATE_PAYMENT_ORDER_REQUEST:
        return {
          loading: true
        }
      case CREATE_PAYMENT_ORDER_SUCCESS:
        return {
          loading: false,
          order: action.payload,
        }
      case CREATE_PAYMENT_ORDER_FAIL:
          return {
            loading: false,
            error: action.payload,
          }
      default:
        return state
    }
  }

  export const getPaymentOrderReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case GET_PAYMENT_ORDER_REQUEST:
        return {
          loading: true
        }
      case GET_PAYMENT_ORDER_SUCCESS:
        return {
          loading: false,
          order: action.payload,
        }
      case GET_PAYMENT_ORDER_FAIL:
          return {
            loading: false,
            error: action.payload,
          }
      default:
        return state
    }
  }

  export const getPaymentDetailsReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
      case GET_PAYMENT_DETAILS_REQUEST:
        return {
          loading: true
        }
      case GET_PAYMENT_DETAILS_SUCCESS:
        return {
          loading: false,
          payment: action.payload,
        }
      case GET_PAYMENT_DETAILS_FAIL:
          return {
            loading: false,
            error: action.payload,
          }
      default:
        return state
    }
  }
  