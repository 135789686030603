// get products
export const getProducts = (products, category, type, limit) => {
  const finalProducts = category
    ? products.filter(
        product => {
          if(product.category.length>=1){
            return product.category.filter(single => single.value === category)[0]
        }else{
          return (product.category[0].value === category)
        }
        }
      )
    : products;

  if (type && type === "new") {
    const newProducts = finalProducts.filter(single => single.new);
    return newProducts.slice(0, limit ? limit : newProducts.length);
  }
  if (type && type === "bestSeller") {
    return finalProducts
      .sort((a, b) => {
        return b.saleCount - a.saleCount;
      })
      .slice(0, limit ? limit : finalProducts.length);
  }
  if (type && type === "saleItems") {
    const saleItems = finalProducts.filter(
      single => single.discount && single.discount > 0
    );
    return saleItems.slice(0, limit ? limit : saleItems.length);
  }
  return finalProducts.slice(0, limit ? limit : finalProducts.length);
};

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : null;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product, color, size) => {
  let productInCart = cartItems.filter(
    single =>
      single.productId=== product._id &&
      (single.selectedProductSize ? single.selectedProductSize === size : true)
  )[0];
  if (cartItems.length >= 1 && productInCart) {
    if (product.variation) {
      let a = cartItems.filter(
        single =>
          single.productId === product._id &&
          single.selectedProductSize === size
      )[0]
      return a? a.units : 0 ;
    } else {
      let b =  cartItems.filter(single => product._id === single._id)[0]
      return b? b.units : 0;
    }
  } else {
    return 0;
  }
};

//get products based on category
export const getSortedProducts = (products, sortType, sortValue) => {
  
  if (products && sortType && sortValue) {
    if (sortType === "category") {
      var categoryProduct = []
      if(sortValue.length>0){
        categoryProduct = products.filter(
          product => {
            return product.category&& product.category.filter(single => {
              if(typeof sortValue === 'string'){
                return single.value === sortValue

          }else{
            for (var i=0; i<sortValue.length; i++){
            
              if(single.value === sortValue[i])
              {
                return true
              }
            }
          }
        
      })[0]})
      return categoryProduct
    }else{
  
      return products
    }

      return products.filter(
        product => product.category.filter(single => single.value === sortValue)[0]
      );
    }
    if (sortType === "tag") {
      console.log(sortValue,products.filter(
        product => product.tag.filter(single => single.value === sortValue)[0]))
      return products.filter(
        product => product.tag.filter(single => single.value === sortValue)[0]
      );
    }

    if (sortType === "color") {


      var colorProduct =[]
      
      colorProduct = products.filter(
        product => {
          for (var i=0; i<sortValue.length; i++){
            if(product.color === sortValue[i])
          {
            return true
          }
        }
      
    })
    return colorProduct
    }

    if (sortType === "size") {
      // return products.filter(
      //   product =>
      //     product.variation &&
      //     product.variation.filter(
      //       single => single.size === sortValue
      //     )[0]
      // );
      var sizeProduct =[]
      if(sortValue.length>0){
      sizeProduct = products.filter(
        product => {
          product.variation=product.productType === "per unit"? product.unitVariation : product.kgVariation
          return product.variation && product.variation.filter(single => {
        for (var i=0; i<sortValue.length; i++){
          if(single.size === sortValue[i])
          {
            return true
          }
        }
      
    })[0]})
    return sizeProduct
  }else{

    return products
  }
    }

 
    
    if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts.sort((a, b) => {
          let c = (a.productType=== "per kg"? a.kgVariation[0].price : a.unitVariation[0].price) 
          let d =  (b.productType=== "per kg"? b.kgVariation[0].price : b.unitVariation[0].price)
          c = c - c * a.discount/100
          d = d - d * b.discount/100
          return d-c
          // return a.productType === "per unit"? (b.unitVariation[0].price-b.unitVariation[0].price*b.discount/100) - (a.unitVariation[0].price-a.unitVariation[0].price*a.discount/100) : (b.kgVariation.price-b.kgVariation.price*b.discount/100) - (a.kgVariation.price-a.kgVariation.price*a.discount/100)
        });
      }
      if (sortValue === "priceLowToHigh") {
        
        return sortProducts.sort((a, b) => {
          let c = (a.productType=== "per kg"? a.kgVariation[0].price : a.unitVariation[0].price) 
          let d =  (b.productType=== "per kg"? b.kgVariation[0].price : b.unitVariation[0].price)
          c = c - c * a.discount/100
          d = d - d * b.discount/100
          return c-d
          // return a.productType === "per unit"? (a.unitVariation[0].price-a.unitVariation[0].price*a.discount/100) - (b.unitVariation[0].price-b.unitVariation[0].price*b.discount/100) : (a.kgVariation.price-a.kgVariation.price*a.discount/100) - (b.kgVariation.price-a.kgVariation.price*a.discount/100)
        });
      }
      if(sortValue === "popularity"){
        return sortProducts.sort((a, b) => {
          return b.saleCount - a.saleCount;
        });
      }

      // if(sortValue === "new"){
      //   return sortProducts.filter((a) => {
      //     return a.new
      //   });
      // }

      if (sortValue === "weightLowToHigh") {
        return sortProducts.sort((a, b) => {
          return a.kgVariation[0].weight- b.kgVariation[0].weight;
        });
      }

      if (sortValue === "weightHighToLow") {
        return sortProducts.sort((a, b) => {
          return b.kgVariation[0].weight- a.kgVariation[0].weight;
        });
      }

    }
    if (sortType === "type") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "per kg") {
        return sortProducts.filter((a) => {
          return a.productType === 'per kg'
        });
      }
      if (sortValue === "per unit") {
        return sortProducts.filter((a) => {
          return a.productType === 'per unit'
        });
      }
       }
       if (sortType === "search") {
         var searchProduct = []
        products.filter(product =>
          {
            if(product.name.toLowerCase().includes(sortValue.toLowerCase())){
              searchProduct = [...searchProduct, product]
          }
          else if(product.color.toLowerCase().includes(sortValue.toLowerCase())){
              searchProduct = [...searchProduct, product]
          }else{
            product.tag.forEach(t => {
              if(t.value.toLowerCase().includes(sortValue.toLowerCase())){
                searchProduct = [...searchProduct, product]
              }
            })
          }
        }
        )
        return searchProduct
    }
  }
  return products;
};

// get individual element
const getIndividualItemArray = array => {
  let individualItemArray = array.filter(function(v, i, self) {
    return i === self.indexOf(v);
  });
  return individualItemArray;
};

// get individual categories
export const getIndividualCategories = products => {
  let productCategories = [];
  products &&
    products.map(product => {
      return (
        product.category &&
        product.category.map(single => {
          return productCategories.push(single.value);
        })
      );
    });
  const individualProductCategories = getIndividualItemArray(productCategories);
  return individualProductCategories;
  // return productCategories
};

// get individual tags
export const getIndividualTags = products => {
  let productTags = [];
  products &&
    products.map(product => {
      return (
        product.tag &&
        product.tag.map(single => {
          return productTags.push(single.value);
        })
      );
    });
  const individualProductTags = getIndividualItemArray(productTags);
  return individualProductTags;
  // return productTags
};

// get individual colors
// export const getIndividualColors = products => {
//   let productColors = [];
//   products &&
//     products.map(product => {
//       return (
//         product.variation &&
//         product.variation.map(single => {
//           return productColors.push(single.color);
//         })
//       );
//     });
//   const individualProductColors = getIndividualItemArray(productColors);
//   return individualProductColors;
// };
export const getIndividualColors = products => {
  let productColors = [];
  products &&
    products.map(product => {
           productColors.push(product.color);
    });
  const individualProductColors = getIndividualItemArray(productColors);
  return individualProductColors;
  // return productColors
};

// export const getSearchProduct = products => {
//   let searchProduct = [];
//   products &&
//     products.map(product => {
//       searchProduct.push(product.color);
//     });
//   const individualSearchProduct = getIndividualItemArray(searchProduct);
//   return individualSearchProduct;
// };

// get individual sizes
export const getProductsIndividualSizes = products => {
  let productSizes1 = ['3XL', '2XL','XL', 'L', 'M', 'S', 'XS', '2Y - 4Y', '4Y - 6Y', '6Y - 8Y', '8Y - 10Y', '10Y - 12Y', '12Y - 14Y', '14Y - 16Y'];
  let productSizes = []
  products &&
    products.map(product => {
      if(product.productType === 'per kg'){
      return (        
        product.kgVariation &&
        product.kgVariation.map(single => {
          if (single.length>=1){
            return single.map(single => {
              return productSizes.push(single.size);
             
            });
          }else{
              return productSizes.push(single.size);
          }
        })
      );
      }else{
        return (        
          product.unitVariation &&
          product.unitVariation.map(single => {
            if (single.length>=1){
            return single.map(single => {
             
                return productSizes.push(single.size);
                
            });
          }else{
              return productSizes.push(single.size);
          }
          })
        );
      }
    });
  const individualProductSizes = getIndividualItemArray(productSizes);

  return productSizes1.filter(value => individualProductSizes.includes(value));
  // return productSizes
};

// get product individual sizes
export const getIndividualSizes = product => {
  let productSizes = [];
  product.variation &&
    product.variation.map(singleVariation => {
      return (
        singleVariation.size &&
        singleVariation.size.map(singleSize => {
          return productSizes.push(singleSize.name);
        })
      );
    });
  const individualSizes = getIndividualItemArray(productSizes);
  return individualSizes;
};

export const setActiveSort = e => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
  );
  filterButtons.forEach(item => {
    item.classList.remove("active");
  });
  // if(e.currentTarget.classList.contains("active")){
  //   console.log(e.currentTarget.classList.contains("active"))
  //   e.currentTarget.classList.remove("active");
  // }else{
  e.currentTarget.classList.add("active");
  
};

export const setActiveLayout = e => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach(item => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const toggleShopTopFilter = e => {
  const shopTopFilterWrapper = document.querySelector(
    "#product-filter-wrapper"
  );
  shopTopFilterWrapper.classList.toggle("active");
  if (shopTopFilterWrapper.style.height) {
    shopTopFilterWrapper.style.height = null;
  } else {
    shopTopFilterWrapper.style.height =
      shopTopFilterWrapper.scrollHeight + "px";
  }
  e.currentTarget.classList.toggle("active");
};
