import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swiper from "react-id-swiper";
import { getProductCartQuantity } from "../../helpers/product";
import { addToCart, getCartList, increaseQuantity, updateCartItem } from "../../redux/actions/cartActions";
import { Modal } from "react-bootstrap";
import Rating from "./sub-components/ProductRating";
import { connect } from "react-redux";

function ProductModal(props) {
  const { product } = props;
  const { currency } = props;
  const { discountedprice } = props;
  const { finalproductprice } = props;
  const { finaldiscountedprice } = props;
  const dispatch = useDispatch()

  product.variation = product.productType === 'per unit'? product.unitVariation : product.kgVariation
  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);
  const [productValue, setProductValue] = useState({})
  const [selectedProductColor, setSelectedProductColor] = useState(
    product.color ? product.color.value : ""
  );

  const [selectedProductSize, setSelectedProductSize] = useState(
    product.variation ? product.variation.filter(v=>v.stock>0 && (product.productType === "per kg"? v.weight>0 : v.price>0))[0]?product.variation.filter(v=>v.stock>0 && (product.productType === "per kg"? v.weight>0 : v.price>0))[0].size : "" : ""
  );
  const [productStock, setProductStock] = useState(
    product.variation ? product.variation.filter(v=>v.stock>0 && (product.productType === "per kg"? v.weight>0 : v.price>0))[0]?product.variation.filter(v=>v.stock>0 && (product.productType === "per kg"? v.weight>0 : v.price>0))[0].stock:0 : 0
  );
  const [quantityCount, setQuantityCount] = useState(1);

  const wishlistItem = props.wishlistitem;
  const compareItem = props.compareitem;

  const addToCart = props.addtocart;
  const addToWishlist = props.addtowishlist;
  const addToCompare = props.addtocompare;

  const addToast = props.addtoast;
  const cartItems = props.cartitems;

  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
    selectedProductColor,
    selectedProductSize
  );


  const userLogin = useSelector((state) => state.userLogin)
    const {userInfo } = userLogin

  const addToCartHandler = (
    product,
    addToast,
    quantityCount,
    selectedProductColor,
    selectedProductSize
  ) => {
    if(userInfo && userInfo.token){
    const cartProduct = cartItems.filter(p=> (p.productId === product._id) && (p.selectedProductSize === selectedProductSize))[0]
    if(!cartProduct){
      dispatch(addToCart(
        product,
        addToast,
        quantityCount,
        selectedProductColor,
        selectedProductSize
      ))

      // dispatch(getCartList())
      setProductStock(productStock-quantityCount)
      
    }else{
    
    cartProduct.selectedProductColor = selectedProductColor
      cartProduct.selectedProductSize = selectedProductSize
      cartProduct.quantity = cartProduct.quantity + quantityCount
      if(cartProduct.quantity <= productStock){
      increaseQuantity(cartProduct, addToast)
      }
      setProductStock(productStock-quantityCount)
    }
  }else{
    props.history.push('/login-register')
  }
  
  }

  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);

  useEffect(() => {
    if(product){
      // product.variation = product.productType === "per unit"? product.unitVariation : product.kgVariation
      setProductValue(selectedProductSize && (product.variation.filter(p => p.size === selectedProductSize)[0]))
    }

  }, [selectedProductSize])

  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    spaceBetween: 10,
    loopedSlides: 4,
    loop: true
  };

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    loopedSlides: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <i className="pe-7s-angle-left" />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <i className="pe-7s-angle-right" />
      </button>
    )
  };

  const color = ['red', 'blue', 'green','yellow','pink', 'black', 'white', 'darkblue', 'skyblue']

  return (
    <Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        className="product-quickview-modal-wrapper"
      >
        <Modal.Header closeButton></Modal.Header>

        <div className="modal-body">
          <div className="row">
            <div className="col-md-5 col-sm-12 col-xs-12">
              <div className="product-large-image-wrapper">
                <Swiper {...gallerySwiperParams}>
                  {product.images &&
                    product.images.map((single, key) => {
                      return (
                        <div key={key}>
                          <div className="single-image">
                            <img
                              src={single.secureUrl}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                </Swiper>
              </div>
              <div className="product-small-image-wrapper mt-15">
                <Swiper {...thumbnailSwiperParams}>
                  {product.images &&
                    product.images.map((single, key) => {
                      return (
                        <div key={key}>
                          <div className="single-image">
                            <img
                              src={single.secureUrl}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                </Swiper>
              </div>
            </div>
            <div className="col-md-7 col-sm-12 col-xs-12">
              <div className="product-details-content quickview-content">
                <h2>{product.name}</h2>
                <div className="product-details-price">
                  {product.productType==='per unit'?(discountedprice !== null ? (
                    <Fragment>
                      <span>
                        {'Rs. ' + (product.discount? (productValue.price - (productValue.price*product.discount/100)): 0)}
                      </span>{" "}
                      <span className="old">
                        {'Rs. ' + (productValue.price? productValue.price : 0)}
                      </span>
                    </Fragment>
                  ) : (
                    <span>{'Rs. ' + (productValue.price? productValue.price : 0)} </span>
                  )):
                  <span>{product.kgVariation.filter(v=>v.stock>0 && v.weight>0 )[0] && product.kgVariation.filter(v=>v.stock>0 && v.weight>0 && (v.size === selectedProductSize))[0].weight}g</span>
                  }
                </div>
                {product.rating && product.rating > 0 ? (
                  <div className="pro-details-rating-wrap">
                    <div className="pro-details-rating">
                      <Rating ratingValue={product.rating} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="pro-details-list">
                  <p className="pb-3">{product.description}</p>
                </div>

                {product.variation ? (
                  <div className="pro-details-size-color">
                    <div className="pro-details-color-wrap me-5">
                      <span>Color</span>
                      <div className="pro-details-color-content ms-2">
                        
                          
                            <label
                              className={`pro-details-color-content--single`}
                            >{" "}
                              <span className="colorbox" style={{backgroundColor: product.color.value}}></span>
                            </label>

                        
                      </div>
                    </div>
                    <div className="pro-details-size">
                      <span>Size</span>
                      <div className="pro-details-size-content">
                        {product.variation &&
                          product.variation.filter(v=>v.stock>0 && (product.productType === "per kg"? v.weight>0 : v.price>0)).map((single,key) => {
                                  return (
                                    <label
                                      className={`pro-details-size-content--single`}
                                      key={key}
                                    >
                                      <input
                                        type="radio"
                                        value={single.size}
                                        checked={
                                          single.size ===
                                          selectedProductSize
                                            ? "checked"
                                            : ""
                                        }
                                        onChange={() => {
                                          setSelectedProductSize(
                                            single.size
                                          );
                                          setProductStock(single.stock);
                                          setQuantityCount(1);
                                        }}
                                      />
                                      <span className="size-name">
                                        {single.size}
                                      </span>
                                    </label>
                                  );
                                
                              
                          })}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {product.affiliateLink ? (
                  <div className="pro-details-quality">
                    <div className="pro-details-cart btn-hover">
                      <a
                        href={product.affiliateLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Buy Now
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="pro-details-quality">
                    <div className="cart-plus-minus">
                      <button
                        onClick={() =>
                          setQuantityCount(
                            quantityCount > 1 ? quantityCount - 1 : 1
                          )
                        }
                        className="dec qtybutton"
                      >
                        -
                      </button>
                      <input
                        className="cart-plus-minus-box"
                        type="text"
                        value={quantityCount}
                        readOnly
                      />
                      <button
                        onClick={() =>
                          setQuantityCount(
                            quantityCount < productStock - productCartQty
                              ? quantityCount + 1
                              : quantityCount
                          )
                        }
                        className="inc qtybutton"
                      >
                        +
                      </button>
                    </div>
                    <div className="pro-details-cart btn-hover">
                      {productStock && productStock > 0 ? (
                        <button
                          onClick={() =>
                            addToCartHandler(
                              product,
                              addToast,
                              quantityCount,
                              selectedProductColor,
                              selectedProductSize
                            )
                          }
                          disabled={productCartQty >= productStock}
                        >
                          {" "}
                          Add To Cart{" "}
                        </button>
                      ) : (
                        <button disabled>Out of Stock</button>
                      )}
                    </div>
                    <div className="pro-details-wishlist">
                      <button
                        className={wishlistItem !== undefined ? "active" : ""}
                        disabled={wishlistItem !== undefined}
                        title={
                          wishlistItem !== undefined
                            ? "Added to wishlist"
                            : "Add to wishlist"
                        }
                        onClick={() => addToWishlist(product, addToast)}
                      >
                        <i className="pe-7s-like" />
                      </button>
                    </div>
                    {/* <div className="pro-details-compare">
                      <button
                        className={compareItem !== undefined ? "active" : ""}
                        disabled={compareItem !== undefined}
                        title={
                          compareItem !== undefined
                            ? "Added to compare"
                            : "Add to compare"
                        }
                        onClick={() => addToCompare(product, addToast)}
                      >
                        <i className="pe-7s-shuffle" />
                      </button>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

ProductModal.propTypes = {
  addtoast: PropTypes.func,
  addtocart: PropTypes.func,
  addtocompare: PropTypes.func,
  addtowishlist: PropTypes.func,
  cartitems: PropTypes.array,
  compareitem: PropTypes.object,
  currency: PropTypes.object,
  discountedprice: PropTypes.number,
  finaldiscountedprice: PropTypes.number,
  finalproductprice: PropTypes.number,
  onHide: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
  wishlistitem: PropTypes.object
};

const mapStateToProps = state => {
  return {
    cartitems: state.cartData
  };
};

export default connect(mapStateToProps)(ProductModal);
