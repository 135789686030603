import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import Paginator from 'react-hooks-paginator';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { connect, useDispatch } from 'react-redux';
import { getSortedProducts, getFilterSortedProducts } from '../../helpers/product2';
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import ShopSidebar from '../../wrappers/product/ShopSidebar';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import ShopProducts from '../../wrappers/product/ShopProducts';
import {
    getIndividualCategories,
  } from "../../helpers/product";
import { fetchProducts } from "../../redux/actions/productActions";
import { getAdminValue } from "../../redux/actions/adminValueActions";
import ProductImageGallerySlider from "../../components/product/ProductImageGallerySlider";


const KgProductsTag = ({location, products, match}) => {
    const [layout, setLayout] = useState('grid three-column');
    const [sortType, setSortType] = useState([]);
    const [sortValue, setSortValue] = useState([]);
    const [filterSortType, setFilterSortType] = useState('');
    const [filterSortValue, setFilterSortValue] = useState('');
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const category = match.params.category.replace("-"," ")
    const uniqueCategories = getIndividualCategories(products);
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(fetchProducts())
    },[])

    useEffect(()=>{
        if(sortValue.length === 0 && sortType.length !== 0){
            setSortType([])
            // setSortValue([])
        }
    }, [sortValue, sortType])


//     const productList = useSelector((state) => state.productList)
//   const { loading , error, products:pro } = productList

//   var products = pro

    // useEffect(()=>{
    //     dispatch(fetchProducts())
    // },[])
    

    const findCategory = uniqueCategories.filter(p => p === category)[0]
    
    useEffect(()=>{
        if(findCategory){
        setSortType(["category"])
        setSortValue([category])
        }else{
            // console.log(category)
            setFilterSortType("tag")
            setFilterSortValue(category)
        }
        setFilterSortValue(category)
        products = getSortedProducts(sortedProducts, "category",category);
    },[category])

    const pageLimit = 50;
    const {pathname} = location;

    const getLayout = (layout) => {
        setLayout(layout)
    }

    const getSortParams = (type, value) => {
        // setSortType([...sortType, type]);
        // if(type === "search"){
        //     setSortValue(sortValue)
        // }else{
        // setSortValue([...sortValue, value]);
        // }
        // console.log(888,sortValue, sortValue.unshift(value))
        // setSortType([sortType]);
        // setSortValue([sortValue]);
        if(type && value.length>0){
        setSortType([...sortType, type]);
        if(type === "search"){
            setSearchValue(value)
            setSortValue([...sortValue,value])
            }else{
                setSortValue([...sortValue, ...value]);
            }
        }else{
            setSortType(sortType.filter(st=>st !== type))
        }
        
    }

    const removeSortParams = (type, value) => {
        // console.log(11, type, value)
        setSortValue(sortValue.filter(v=> v !== value))
        if(sortType.filter(t=>t===type).length > 0){
            var a = sortType.findIndex(t=>t===type)
            
        setSortType(sortType.filter((t,i)=>i !== a))
        }
    }

    const getFilterSortParams = (sortType, sortValue) => {
        setFilterSortType(sortType);
        setFilterSortValue(sortValue);
    }

    useEffect(()=>{
        dispatch(getAdminValue())
    },[])

    useEffect(() => {
        if(match.params.category){
            let productList = getFilterSortedProducts(products, 'type', 'per kg');
            let sortedProducts = getSortedProducts(productList, sortType, sortValue, searchValue);
        const filterSortedProducts = getFilterSortedProducts(sortedProducts, filterSortType, filterSortValue);
        sortedProducts = filterSortedProducts;
        setSortedProducts([sortedProducts]);
        setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
            // console.log(89,sortValue, sortType)
        }else{
        let sortedProducts = getSortedProducts(products, sortType, sortValue, searchValue);
        const filterSortedProducts = getFilterSortedProducts(sortedProducts, filterSortType, filterSortValue);
        sortedProducts = filterSortedProducts;
        setSortedProducts([sortedProducts]);
        setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
        // console.log(88,sortValue, sortType)
        }
    }, [offset, products, sortType, sortValue, filterSortType, filterSortValue, match ]);

    return (
        <Fragment>
            <MetaTags>
                <title>Zmikas | Shop Page</title>
                <meta name="description" content="Shop page of Zmikas" />
            </MetaTags>

            {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + '/'}>Home</BreadcrumbsItem>
            <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>Collections</BreadcrumbsItem> */}

            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                {/* <Breadcrumb /> */}
                {/* <div className="banner-area pt-40 pb-0"><div className="container"><div className="row"><div className="col-sm-6 col-12"><div className="single-banner mb-4"><a href="/treadingclothes"><img src="/assets/img/banner/banner-10.webp" alt="" /></a></div></div><div className="col-sm-6 col-12"><div className="single-banner "><a href="/treadingclothes"><img src="/assets/img/banner/banner-11.webp" alt="" /></a></div></div></div></div></div> */}
                {/* <div className="bgslider sticky-top">
                <ProductImageGallerySlider product={products}/>
                </div> */}
                <div className="shop-area pt-60 pb-80">
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-lg-3 order-1 order-lg-1">
                                {/* shop sidebar */}
                                <ShopSidebar products={products} removeSortParams={removeSortParams} getSortParams={getSortParams} sideSpaceclassName="mr-30" getFilterSortParams={getFilterSortParams} sortedProducts={sortedProducts}/>
                                {/* <button onClick={}>Filter</button> */}
                            </div>
                            <div className="col-lg-9 order-2 order-lg-2">
                                {/* shop topbar default */}
                                <ShopTopbar getLayout={getLayout} getFilterSortParams={getFilterSortParams} productCount={products.length} sortedProductCount={currentData.length} products={currentData} removeSortParams={removeSortParams} getSortParams={getSortParams} sortedProducts={sortedProducts} sortType={sortType} sortValue={sortValue}/>

                                {/* shop page content default */}
                                <ShopProducts layout={layout} products={currentData} />

                                {/* shop product pagination */}
                                <div className="pro-pagination-style text-center mt-30">
                                    <Paginator
                                        totalRecords={sortedProducts.length}
                                        pageLimit={pageLimit}
                                        pageNeighbours={2}
                                        setOffset={setOffset}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        pageContainerclassName="mb-0 mt-0"
                                        pagePrevText="«"
                                        pageNextText="»"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    )
}

KgProductsTag.propTypes = {
  location: PropTypes.object,
  products: PropTypes.array
}

const mapStateToProps = state => {
    return{
        products: state.productList.products
    }
}

export default connect(mapStateToProps)(KgProductsTag);